import { useState, useRef, ComponentProps, lazy, useEffect } from 'react';
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import LogoPath from '@assets//images/Logo_horizontal@2x.png';
import { PublicText } from '@atoms/texts';
import AccordionArrowUp from '@assets/svgs/Sidebar/AccordionArrowUp';
import AccordionArrowDown from '@assets/svgs/Sidebar/AccordionArrowDown';
import { useSidebarInfoMutation } from '@features/api';
import {
  useTypedSelector,
  useTypedDispatch,
  RootState,
} from '@features/rootStore';
import { setCompanyName } from '@features/auth';
import { useResponsive, useTokenRefresh } from '@hooks';
import { ETC, errorCodes as ERROR } from '@constants';
import { addCommaToNumber } from '@utils';
import * as S from './Layout.style';

const navServices = [
  { id: nanoid(), eng: 'Startag', ko: '스타태그' },
  { id: nanoid(), eng: 'ExposedAccount', ko: '인기게시물 노출계정 분석' },
  { id: nanoid(), eng: 'Account', ko: '계정 분석' },
  { id: nanoid(), eng: 'PostIncrease', ko: '게시물 증가 추이' },
  { id: nanoid(), eng: 'TopAccount', ko: 'TOP 계정 정보' },
  { id: nanoid(), eng: 'HotHashtag', ko: 'HOT 해시태그 정보' },
];
const navSecondary = [
  // {
  //   id: nanoid(),
  //   category: '고객센터',
  //   iconName: 'CustomerCenter',
  //   items: [
  //     { id: nanoid(), eng: 'notice', ko: '공지사항' },
  //     { id: nanoid(), eng: 'faq', ko: 'FAQ' },
  //   ],
  // },
  {
    id: nanoid(),
    category: '마이페이지',
    iconName: 'Mypage',
    items: [
      { id: nanoid(), eng: 'manage', ko: '계정관리' },
      { id: nanoid(), eng: 'history/charge', ko: '포인트 충전 내역' },
      { id: nanoid(), eng: 'history/purchase', ko: '구매 내역' },
    ],
  },
];
const Logo = () => {
  return (
    <S.Logo>
      <img
        src={LogoPath}
        alt="logo"
        style={{ width: '172px', height: '36px' }}
      />
    </S.Logo>
  );
};
const UserInfo = () => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const { serviceApiCallDetectCount } = useTypedSelector(
    ({ rootReducer }: RootState) => ({
      serviceApiCallDetectCount:
        rootReducer.authReducers.serviceApiCallDetectCount,
    })
  );
  const { refresh } = useTokenRefresh();
  const [sidebarInfo] = useSidebarInfoMutation();

  const [name, setName] = useState<string>('');
  const [point, setPoint] = useState<number>(0);

  useEffect(() => {
    sidebarInfo({})
      .unwrap()
      .then(({ name: userName, point: userPoint, corporation }) => {
        dispatch(setCompanyName(corporation));
        setName(userName);
        setPoint(userPoint);
      })
      .catch(({ data }) => {
        if (data.code === ERROR.LOGIN_EXPIRED) {
          window.alert(t('translation.SESSION_EXPIRATION'));
          refresh();
        }
      });
  }, [serviceApiCallDetectCount]);
  return (
    <S.UserInfo>
      {name && (
        <PublicText
          value={name}
          style={{ color: '#555555', fontWeight: 'bold' }}
        />
      )}
      <S.Point>
        <PublicText
          value={`${t('translation.SIDE_MENU_REMAIN_POINT')}:`}
          style={{ color: '#555555' }}
        />
        &nbsp;
        {point && (
          <PublicText
            value={`${addCommaToNumber(point)}`}
            style={{ fontWeight: 'bold' }}
          />
        )}
      </S.Point>
    </S.UserInfo>
  );
};
interface AccordionInterface {
  title: string;
  iconName: string;
  contents: Record<string, string>[];
}
const Accordion = ({ title, iconName, contents }: AccordionInterface) => {
  const { t } = useTranslation();
  const contentsRef = useRef<HTMLDivElement>(null);

  const [isCollapse, setIsCollapse] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const Icon = lazy(() => import(`@assets/svgs/Sidebar/${iconName}`));

  return (
    <S.Accordion>
      <S.AccordionTitle
        isCollapse={isCollapse}
        onClick={() => setIsCollapse(prev => !prev)}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={{
          padding: '0 20px',
        }}
      >
        <S.IconWrapper>
          <Icon color={isHover || isCollapse ? '#4A5BFD' : '#A0A5B7'} />
        </S.IconWrapper>
        {title === '고객센터' && t('translation.COUSTOMER_SERVICE_CENTER')}
        {title === '마이페이지' && t('translation.MYPAGE')}
        <S.AccordionArrowWrapper>
          {isCollapse ? <AccordionArrowUp /> : <AccordionArrowDown />}
        </S.AccordionArrowWrapper>
      </S.AccordionTitle>
      <S.AccordionItems
        ref={contentsRef}
        style={
          isCollapse
            ? { height: contentsRef.current?.scrollHeight }
            : { height: '0px' }
        }
      >
        {contents.map(content => {
          let text = '';

          if (content.ko === '공지사항') {
            text = t('translation.NOTICE');
          }

          if (content.ko === 'FAQ') {
            text = t('translation.FAQ');
          }

          if (content.ko === '계정관리') {
            text = t('translation.MYPAGE_ACCOUNT_SETTING');
          }

          if (content.ko === '포인트 충전 내역') {
            text = t('translation.MYPAGE_POINT_CHARGE_HISTORY');
          }

          if (content.ko === '구매 내역') {
            text = t('translation.MYPAGE_PURCHASE_HISTORY');
          }

          return (
            <S.AccordionItem key={content.id}>
              <RouterNavLink
                style={{
                  paddingLeft: '50px',
                  width: '100%',
                  color: 'inherit',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                }}
                to={`/${content.eng}`}
                activeStyle={{
                  color: '#4A5BFD',
                }}
              >
                {text}
              </RouterNavLink>
            </S.AccordionItem>
          );
        })}
      </S.AccordionItems>
    </S.Accordion>
  );
};
interface NavServiceLinkProps {
  hover: boolean;
  value: string;
  iconName: string;
}
export const NavService = ({
  hover,
  value,
  iconName,
  ...props
}: NavServiceLinkProps & ComponentProps<typeof RouterNavLink>) => {
  const currentPath = useLocation().pathname.slice(1);
  const Icon = lazy(() => import(`@assets/svgs/Sidebar/${iconName}`));
  return (
    <S.NavService>
      <RouterNavLink
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          textDecoration: 'none',
          width: '100%',
          minHeight: '44px',
          borderRadius: '10px',
          color: 'inherit',
          padding: '9.5px 20px',
        }}
        {...props}
      >
        <S.IconWrapper>
          <Icon
            color={
              currentPath === iconName.toLowerCase() || hover
                ? '#FFFFFF'
                : '#A0A5B7'
            }
          />
        </S.IconWrapper>
        {value}
      </RouterNavLink>
    </S.NavService>
  );
};

// 서비스 메뉴
const NavServices = () => {
  const { t } = useTranslation();
  const [hoverId, setHoverId] = useState('');
  return (
    <S.NavServices>
      {navServices.map(obj => {
        let value = '';

        if (obj.ko === '스타태그') {
          value = t('translation.STAR_TAG');
        }

        if (obj.ko === '인기게시물 노출계정 분석') {
          value = t('translation.POPULAR_POSTING_EXPOSURE_ACCOUNT_ANALYSIS');
        }

        if (obj.ko === '계정 분석') {
          value = t('translation.ACCOUNT_ANALYSIS');
        }

        if (obj.ko === '게시물 증가 추이') {
          value = t('translation.POSTING_INCREASE_TRANSITION');
        }

        if (obj.ko === 'TOP 계정 정보') {
          value = t('translation.TOP_ACCOUNT_INFORMATION');
        }

        if (obj.ko === 'HOT 해시태그 정보') {
          value = t('translation.HOT_HASHTAG_INFORMATION');
        }

        return (
          <NavService
            key={obj.id}
            value={value}
            iconName={obj.eng}
            to={`/${obj.eng.toLowerCase()}`}
            activeStyle={{
              backgroundColor: '#4A5BFD',
              boxShadow: '0 10px 10px rgba(74, 91, 253,0.1)',
              color: '#fff',
            }}
            hover={hoverId === obj.id}
            onMouseEnter={() => setHoverId(obj.id)}
            onMouseLeave={() => setHoverId('')}
          />
        );
      })}
    </S.NavServices>
  );
};

// 고객센터, 마이페이지 메뉴
const NavSecondary = () => {
  return (
    <S.NavSecondary>
      {navSecondary.map(obj => (
        <Accordion
          key={obj.id}
          title={obj.category}
          iconName={obj.iconName}
          contents={obj.items}
        />
      ))}
    </S.NavSecondary>
  );
};

interface LogoutProps {
  onLogout: () => void;
}

const Logout = ({ onLogout }: LogoutProps) => {
  const { t } = useTranslation();
  const [color, setColor] = useState('#a1a1a1');
  return (
    <S.Logout
      onClick={onLogout}
      onMouseEnter={() => setColor('#555555')}
      onMouseLeave={() => setColor('#a1a1a1')}
    >
      <PublicText
        value={t('translation.LOGOUT')}
        style={{
          color,
        }}
      />
    </S.Logout>
  );
};

interface SidebarProps {
  onLogout: () => void;
}

const Sidebar = ({ onLogout }: SidebarProps) => {
  return (
    <S.Sidebar>
      <Logo />
      <UserInfo />
      <NavServices />
      <NavSecondary />
      <Logout onLogout={onLogout} />
    </S.Sidebar>
  );
};

export const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isSubTemplate, setIsSubTemplate] = useState<boolean>(false);

  useEffect(() => {
    if (
      location.pathname === '/manage' ||
      location.pathname === '/history/charge' ||
      location.pathname === '/history/purchase'
    ) {
      setIsSubTemplate(true);
      return;
    }

    setIsSubTemplate(false);
  }, [location.pathname]);

  const Wrapper = styled.div<{ isSubTemplate?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 360px);
    min-height: 60px;
    background: ${props => (props.isSubTemplate ? '#f9f9f9' : '#f9fbff')};
    border-top: 1px solid #ddd;
    margin-left: 360px;
  `;

  const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
  `;

  const Link = styled.a`
    display: block;
    font-size: 14px;
    color: #000;
    text-decoration: underline;
    text-align: right;

    & ~ & {
      margin-left: 8px;
    }
  `;

  return (
    <Wrapper isSubTemplate={isSubTemplate}>
      <LinkWrapper>
        <Link href="/terms/service" target="_blank" rel="noreferrer noopener">
          {t('translation.TERMS_SERVICE')}
        </Link>
        <Link href="/terms/person" target="_blank" rel="noreferrer noopener">
          {t('translation.TERMS_PERSON')}
        </Link>
        <Link href="/terms" target="_blank" rel="noreferrer noopener">
          {t('translation.TERMS')}
        </Link>
      </LinkWrapper>
    </Wrapper>
  );
};

interface Props {
  children: React.ReactNode;
  onLogout: () => void;
}

export const Layout = ({ children, onLogout }: Props) => {
  const { isAllowed } = useResponsive();

  return (
    <S.Container>
      {!isAllowed && (
        <S.NotAllowed>
          <img
            style={{
              position: 'absolute',
              width: '394px',
              height: '164px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -80%)',
            }}
            src={ETC.onlypc}
            alt="only pc available"
          />
        </S.NotAllowed>
      )}
      <Sidebar onLogout={onLogout} />
      <S.Main>{children}</S.Main>
      <Footer />
    </S.Container>
  );
};
