import { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useTypedSelector, RootState } from '@features/rootStore';

const PrivateRoute = ({ children, ...rest }: any) => {
  const { accessToken } = useTypedSelector(({ rootReducer }: RootState) => ({
    accessToken: rootReducer.authReducers.accessToken,
  }));
  useEffect(() => {
    if (!accessToken) {
      window.alert('로그인 후 이용해주세요');
    }
  }, []);

  return (
    <Route {...rest}>
      {accessToken ? children : <Redirect to={{ pathname: '/' }} />}
    </Route>
  );
};
export default PrivateRoute;
