import {
  usePurchaseHistoryMutation,
  useGetPurchaseHistoryItemMutation,
  useReorderMutation,
  useSaveByOrderIdxMutation,
  useChargeHistoryQuery,
} from '@features/api';
import { addCommaToNumber } from '@utils';
import { useTranslation } from 'react-i18next';

export const useMypageApi = () => {
  const { t } = useTranslation();
  const [reorder] = useReorderMutation();
  const [saveByOrderIdx] = useSaveByOrderIdxMutation();
  const [getPurchaseHistoryItem] = useGetPurchaseHistoryItemMutation();
  const [purchaseHistory] = usePurchaseHistoryMutation();

  const getPurchaseHistory = async () => {
    const responseData = await purchaseHistory({})
      .unwrap()
      .then(res => {
        const processedRes = res.list.map((e: any) => {
          return {
            status: e.status,
            no: e.idx,
            service: e.name,
            keyword: e.keyword,
            type:
              e.type === 1
                ? t('translation.TABLE_TYPE_TYPE1')
                : t('translation.TABLE_TYPE_TYPE2'),
            point: addCommaToNumber(e.price),
            date: e.add_date,
            basedate: e.base_date,
            recipient: e.recipient_name,
            lookup: e.type === 1,
            save: e.type === 2,
            viewKey: {
              serviceCode: e.service_code,
              idx: e.idx,
              type: e.type,
              price: e.price,
              add_date: e.add_date,
            },
            saveKey: {
              reportNo: e.idx,
              filename: e.filename,
            },
          };
        });
        return processedRes;
      })
      .catch(err => {
        return err;
      });
    return responseData;
  };
  const getChargeHistory = () => {
    const history = useChargeHistoryQuery({});
    return history;
  };
  const getPurchasedServiceView = async (viewKey: any) => {
    const responseData = await getPurchaseHistoryItem(viewKey)
      .unwrap()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
    return responseData;
  };
  const checkPurchasedReport = async (reportNo: any) => {
    const responseData = await saveByOrderIdx({ order_idx: reportNo })
      .unwrap()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
    return responseData;
  };
  const reorderReport = async (reportNo: any) => {
    const responseData = await reorder({ order_idx: reportNo })
      .unwrap()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
    return responseData;
  };

  return {
    getPurchaseHistory,
    getChargeHistory,
    getPurchasedServiceView,
    checkPurchasedReport,
    reorderReport,
  };
};
