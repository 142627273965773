import { useState, useMemo, useEffect } from 'react';
import type { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { SubTemplate } from '@templates/SubTemplate';
import { useTokenRefresh, useMypageApi } from '@hooks';
import { addCommaToNumber } from '@utils';
import { Table } from './Table';
import * as S from './ChargeHistory.style';

interface ChargeTable {
  no: string;
  method: string;
  type: string;
  point: string;
  date: string;
}
const ChargeHistory: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { refresh } = useTokenRefresh();
  const { getChargeHistory } = useMypageApi();
  const history = getChargeHistory();
  const [tableData, setTableData] = useState<ChargeTable[] | null>(null);

  const columns: Column<ChargeTable>[] = useMemo(
    () => [
      {
        Header: 'NO',
        accessor: 'no' as keyof ChargeTable,
      },
      {
        Header: t('translation.TABLE_PAYMENT_METHOD'),
        accessor: 'method' as keyof ChargeTable,
      },
      {
        Header: t('translation.TABLE_CHARGE_STATUS'),
        accessor: 'type' as keyof ChargeTable,
      },
      {
        Header: t('translation.TABLE_CHARGE_POINT'),
        accessor: 'point' as keyof ChargeTable,
      },
      {
        Header: t('translation.TABLE_CHARGE_DATE'),
        accessor: 'date' as keyof ChargeTable,
      },
    ],
    []
  );

  useEffect(() => {
    if (history.isSuccess && history.data) {
      const processedData = history.data.deposit_list.map((e: any) => {
        let method = '';

        if (e.imp_paid_method === '월정액 충전') {
          method = t('translation.TABLE_PAYMENT_METHOD_TYPE1');
        }

        if (e.imp_paid_method === '신용카드') {
          method = t('translation.TABLE_PAYMENT_METHOD_TYPE2');
        }

        if (e.imp_paid_method === '무통장입금') {
          method = t('translation.TABLE_PAYMENT_METHOD_TYPE3');
        }

        if (e.imp_paid_method === '휴대폰결제') {
          method = t('translation.TABLE_PAYMENT_METHOD_TYPE4');
        }

        let type = '';
        if (e.paid_status === '완료') {
          type = t('translation.TABLE_CHARGE_STATUS_TYPE1');
        }

        if (e.paid_status === '대기') {
          type = t('translation.TABLE_CHARGE_STATUS_TYPE2');
        }

        if (e.paid_status === '결제 취소') {
          type = t('translation.TABLE_CHARGE_STATUS_TYPE3');
        }

        return {
          no: e.idx,
          method,
          type,
          point: addCommaToNumber(e.paid_amount),
          date: e.add_date.substring(0, 10),
        };
      });
      setTableData(processedData);
    }
  }, [history]);

  useEffect(() => {
    refresh();
  }, []);

  return (
    <SubTemplate title={t('translation.MYPAGE_POINT_CHARGE_HISTORY')}>
      <S.Contents>
        {tableData && <Table columns={columns} data={tableData} />}
      </S.Contents>
    </SubTemplate>
  );
};
export default ChargeHistory;
