import type { FC } from 'react';
import { PublicText } from '@atoms/texts';
import * as S from './SubTemplate.style';

const Title = ({ title }: { title: string }) => {
  return (
    <S.Title>
      <PublicText
        value={title}
        style={{
          fontSize: '40px',
          fontWeight: 'bold',
        }}
      />
    </S.Title>
  );
};

interface Props {
  title: string;
  children?: React.ReactNode;
}
export const SubTemplate: FC<Props> = ({ title, children }) => {
  return (
    <S.Container>
      <Title title={title} />
      {children}
    </S.Container>
  );
};
