import styled from 'styled-components';

export const Contents = styled.div`
  position: relative;
  width: 1420px;
`;
export const CountAndTableSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`;
export const DropdownWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;
