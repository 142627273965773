import styled from 'styled-components';
import { SCDMediumText, BoldText, LightText } from '@atoms/texts';

export const LogoAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
`;
export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.tablet`margin-bottom: 30px;`}
  ${({ theme }) => theme.media.desktop`margin-bottom: 16px;`}
`;

export const Title = styled(SCDMediumText)`
  margin-left: 10px;
`;
export const Description = styled(BoldText)``;

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`;
export const Contents = styled.div`
  position: absolute;
  ${({ theme }) => theme.media.tablet`top: 50px;`}
  ${({ theme }) => theme.media.desktop`top: 20px;`}
  left: 50%;
  ${({ theme }) => theme.media.tablet`padding: 0 20px;`}
  ${({ theme }) => theme.media.desktop`width: 460px;`}
  ${({ theme }) => theme.media.tablet`width: 100%;`}
  transform: translateX(-50%);
`;

export const RegistrationForm = styled.form`
  width: 100%;
`;

export const FormInputWithLabel = styled.div<InputStyle>`
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;
export const Label = styled(BoldText)<TextStyle>`
  margin-bottom: 5px;
`;
export const MainSearchInputWithButton = styled.div`
  position: relative;
  width: fit-content;
`;
export const ButtonContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 13px;
  background-color: transparent;
  cursor: pointer;
`;
export const UnderlineInput = styled.input<InputStyle>`
  width: 100%;
  height: 32px;
  border: 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.neutral.fourth}`};
  ${({ theme }) => theme.media.desktop`font-size: ${theme.fontSize.m}px;`}
  ${({ theme }) => theme.media.tablet`font-size: ${theme.fontSize.m}px;`}
  color: ${({ theme }) => theme.color.black.default};
  outline: none;
  &::placeholder {
    color: ${({ theme }) => theme.color.neutral.fourth};
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.color.neutral.fourth};
  }
  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.color.neutral.fourth};
  }
`;

export const FormErrorMessage = styled(LightText)`
  margin-top: 2px;
  width: 100%;
`;
