interface Props {
  color: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export const Search_button = ({ color, ...props }: Props) => {
  return (
    <svg
      id="search_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28.005"
      viewBox="0 0 28 28.005"
      {...props}
    >
      <path
        id="search"
        d="M27.619,24.212l-5.453-5.453a1.312,1.312,0,0,0-.93-.383h-.891a11.371,11.371,0,1,0-1.969,1.969v.891a1.312,1.312,0,0,0,.383.93l5.453,5.453a1.307,1.307,0,0,0,1.854,0l1.548-1.548A1.319,1.319,0,0,0,27.619,24.212ZM11.376,18.376a7,7,0,1,1,7-7A7,7,0,0,1,11.376,18.376Z"
        fill={color}
      />
    </svg>
  );
};
