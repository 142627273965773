import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const UpdateDate = styled.div``;

export const Buttons = styled.div`
  display: flex;
`;
export const GetNew = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ isDisabled }) => (isDisabled ? '#CECECE' : '#000')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;
export const ExportPDF = styled.div<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: ${({ isDisabled }) => (isDisabled ? '#CECECE' : '#000')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;
