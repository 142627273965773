import { useState, useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { Column } from 'react-table';
import download from 'downloadjs';
import { useTranslation } from 'react-i18next';
import { SubTemplate } from '@templates/SubTemplate';
import { Modal } from '@atoms/utils';
import { useTypedDispatch } from '@features/rootStore';
import { orderedItem } from '@features/service';
import { addCommaToNumber } from '@utils';
import { useMypageApi, useTokenRefresh, useModal } from '@hooks';
import { Table } from './Table';
import * as S from './PurchaseHistory.style';

interface PurchaseTable {
  status: number;
  no: string;
  service: string;
  keyword: string;
  type: string;
  point: string;
  date: string;
  basedate: string;
  recipient: string;
  lookup: string;
  save: string;
}

const PurchaseHistory: FC<RouteComponentProps> = () => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const { refresh } = useTokenRefresh();
  const {
    isShown,
    modalContent,
    onModalOpen,
    onModalClose,
    openServiceErrorModal,
  } = useModal();
  const {
    getPurchaseHistory,
    getPurchasedServiceView,
    checkPurchasedReport,
    reorderReport,
  } = useMypageApi();

  const [data, setData] = useState<PurchaseTable[] | null>(null);

  const loadHistoryData = async () => {
    const list = await getPurchaseHistory();

    let res: any[] = [];

    res = list.map((obj: any) => {
      let service = '';

      if (obj.service === '스타태그 - 단어포함 해시태그') {
        service = `${t('translation.STAR_TAG')} - ${t(
          'translation.WORD_INCLUDE_HASHTAG'
        )}`;
      }

      if (obj.service === '스타태그 - 추천자동/연관해시태그') {
        service = `${t('translation.STAR_TAG')} - ${t(
          'translation.WORD_INCLUDE_HASHTAG'
        )}`;
      }

      if (obj.service === '인기게시물 노출계정 분석') {
        service = t('translation.POPULAR_POSTING_EXPOSURE_ACCOUNT_ANALYSIS');
      }

      if (obj.service === '계정 분석') {
        service = t('translation.ACCOUNT_ANALYSIS');
      }

      if (obj.service === '게시물 증가 추이') {
        service = t('translation.POSTING_INCREASE_TRANSITION');
      }

      if (obj.service === 'TOP 계정정보') {
        service = t('translation.TOP_ACCOUNT_INFORMATION');
      }

      if (obj.service === 'HOT 해시태그 정보') {
        service = t('translation.HOT_HASHTAG_INFORMATION');
      }

      return {
        ...obj,
        service,
      };
    });

    setData(res);
  };

  useEffect(() => {
    refresh();
    loadHistoryData();
  }, []);

  // 서비스 조회
  const handlePurchasedDataView = async (viewKey: any) => {
    const purchasedServiceData = await getPurchasedServiceView(viewKey);
    dispatch(
      orderedItem({ ...purchasedServiceData, serviceCode: viewKey.serviceCode })
    );
    const width = viewKey.serviceCode === 100 ? '730' : '1480';
    window.open('/ordered', '_blank', `height=1000, width=${width}`);
  };

  // 리포트 다운로드
  const handlePurchasedReportSave = async (saveKey: any) => {
    const checkResponseData = await checkPurchasedReport(saveKey.reportNo);
    if (checkResponseData) {
      if (checkResponseData.status >= 400) {
        openServiceErrorModal(checkResponseData.data.code);
      } else {
        const executedApi = async () => {
          const reorderResponseData = await reorderReport(saveKey.reportNo);
          if (reorderResponseData) {
            if (reorderResponseData.status >= 400) {
              openServiceErrorModal(reorderResponseData.data.code);
            } else {
              download(reorderResponseData, `${saveKey.filename}`);
              loadHistoryData();
            }
          }
        };
        onModalOpen({
          onFirstButtonClick: executedApi,
          message: t('translation.PREMIUM_SERVICE'),
          subMessage: [
            `${t('translation.PREMIUM_SERVICE_TEXT3')} ${addCommaToNumber(
              checkResponseData.price
            )}${t('translation.PREMIUM_SERVICE_TEXT2')}`,
          ],
          firstButtonText: t('translation.REPORT_SAVE_BUTTON'),
        });
      }
    }
  };

  const columns: Column<PurchaseTable>[] = useMemo(
    () => [
      {
        Header: t('translation.TABLE_ORDER_NUMBER'),
        accessor: 'no' as keyof PurchaseTable,
      },
      {
        Header: t('translation.TABLE_USE_SERVICE'),
        accessor: 'service' as keyof PurchaseTable,
      },
      {
        Header: t('translation.TABLE_SEARCH_KEYWORD'),
        accessor: 'keyword' as keyof PurchaseTable,
      },
      {
        Header: t('translation.TABLE_TYPE'),
        accessor: 'type' as keyof PurchaseTable,
      },
      {
        Header: t('translation.TABLE_EXHAUST_POINT'),
        accessor: 'point' as keyof PurchaseTable,
      },
      {
        Header: t('translation.TABLE_PURCHASE_DATE'),
        accessor: 'date' as keyof PurchaseTable,
      },
      {
        Header: t('translation.TABLE_DATA_UPDATE_DATE'),
        accessor: 'basedate' as keyof PurchaseTable,
      },
      {
        Header: t('translation.TABLE_INQUIRY_SAVE'),
        accessor: 'lookup' as keyof PurchaseTable,
      },
    ],
    []
  );

  return (
    <>
      <SubTemplate title={t('translation.MYPAGE_PURCHASE_HISTORY')}>
        <S.Contents>
          {data && (
            <Table
              onView={handlePurchasedDataView}
              onSave={handlePurchasedReportSave}
              columns={columns}
              data={data}
            />
          )}
        </S.Contents>
      </SubTemplate>
      <Modal
        isShown={isShown}
        modalContent={modalContent}
        onClose={onModalClose}
      />
    </>
  );
};
export default PurchaseHistory;
