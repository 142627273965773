import styled, { css } from 'styled-components';

export const Contents = styled.div`
  width: 1200px;
  height: 400px;
  background-color: #fff;
`;
export const Tabs = styled.div`
  display: flex;
  width: 100%;
`;
export const Tab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 80px;
  border: 1px solid #cecece;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
  &:hover {
    background-color: #555555;
    color: #fff;
  }
`;
export const Accordion = styled.ul`
  padding: 20px;
  list-style: none;
`;
export const AccordionTitle = styled.div`
  height: 50px;
`;
export const AccordionItem = styled.li``;
export const AccordionItemQuestion = styled.button<{ active: boolean }>`
  position: relative;
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  background-color: #fff;
  border: 0;
  border-bottom: ${({ active }) => (active ? '0' : '1px dashed #E8E8E8')};
  cursor: pointer;
  text-align: left;
`;
export const AccordionArrow = styled.div<{ active: boolean }>`
  position: absolute;
  top: 20%;
  right: 0;
  width: 24px;
  height: 24px;
  ${({ active }) => (active ? 'transform: rotate(180deg);' : '')}
  transition: transform 2s;
`;
export const AccordionItemAnswerWrapper = styled.div<{ active: boolean }>`
  ${({ active }) =>
    active
      ? css`
          overflow: 0;
        `
      : 'overflow: hidden;'}
  ${({ active }) =>
    active
      ? css`
          height: fit-content;
        `
      : 'height: 0;'}
  transition: all 0.2s;
`;
export const AccordionItemAnswer = styled.div`
  padding: 0 5px;
  color: #555555;
`;
export const AccordionContents = styled.div``;
