import styled from 'styled-components';
import Background from '@assets/images/Login_background@2x.png';

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url(${Background});
  background-size: 100% 100%;
`;
export const Contents = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  ${({ theme }) => theme.media.desktop`width: 1150px;`}
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
  transform: translate(-50%, -50%);
  overflow: hidden;
`;
