import Carousel1 from '@assets/images/Login_carousel1@2x.png';
import Carousel2 from '@assets/images/Login_carousel2@2x.png';
import Carousel3 from '@assets/images/Login_carousel3@2x.png';
import CarouselM1 from '@assets/images/Login_carouselmessage1@2x.png';
import CarouselM2 from '@assets/images/Login_carouselmessage2@2x.png';
import CarouselM3 from '@assets/images/Login_carouselmessage3@2x.png';
import STARTAG__tablefiltersearh from '@assets/images/Startag_tablefiltersearchbutton@2x.png';
import TOPACCOUNTS_ICONS_score from '@assets/images/TopAccounts_score@2x.png';
import TOPACCOUNTS_ICONS_like from '@assets/images/TopAccounts_like@2x.png';
import TOPACCOUNTS_ICONS_follower from '@assets/images/TopAccounts_follower@2x.png';
import TOPACCOUNTS_ICONS_post from '@assets/images/TopAccounts_post@2x.png';
import HOTHASHTAG_ICONS_increase from '@assets/images/HotHashtag_increase@2x.png';
import HOTHASHTAG_ICONS_daily from '@assets/images/HotHashtag_daily@2x.png';
import HOTHASHTAG_ICONS_amount from '@assets/images/HotHashtag_amount@2x.png';
import MAININITTEMPLATE_LOGO_startag from '@assets/images/MainInitTemplate_logo_startag@2x.png';
import MAININITTEMPLATE_LOGO_exposedaccount from '@assets/images/MainInitTemplate_logo_exposedaccount@2x.png';
import MAININITTEMPLATE_LOGO_account from '@assets/images/MainInitTemplate_logo_account@2x.png';
import MAININITTEMPLATE_LOGO_postincrease from '@assets/images/MainInitTemplate_logo_postincrease@2x.png';
import MAININITTEMPLATE_ICONS_startag from '@assets/images/MainInitTemplate_startag@2x.png';
import MAININITTEMPLATE_ICONS_account from '@assets/images/MainInitTemplate_account@2x.png';
import MAININITTEMPLATE_ICONS_exposedaccount from '@assets/images/MainInitTemplate_exposedaccount@2x.png';
import MAININITTEMPLATE_ICONS_postincrease from '@assets/images/MainInitTemplate_postincrease@2x.png';
import MAININITTEMPLATE_ICONS_targetuser from '@assets/images/MainInitTemplate_targetuser@2x.png';
import MAININITTEMPLATE_ICONS_analysis from '@assets/images/MainInitTemplate_analysis@2x.png';
import SMALLPUBLICBOX_ICONS_exposedaccount_date from '@assets/images/ExposedAccount_dateicon@2x.png';
import SMALLPUBLICBOX_ICONS_exposedaccount_hashtag from '@assets/images/ExposedAccount_hashtagicon@2x.png';
import SMALLPUBLICBOX_ICONS_exposedaccount_post from '@assets/images/ExposedAccount_posticon@2x.png';
import SMALLPUBLICBOX_ICONS_account_post from '@assets/images/Account_dateicon@2x.png';
import SMALLPUBLICBOX_ICONS_postincrease_monthlyincrease from '@assets/images/PostIncrease_monthlyincreaseicon@2x.png';
import TABLE_PAGINATION_gotofirst from '@assets/images/TablePagination_gotofirst@2x.png';
import TABLE_PAGINATION_gotolast from '@assets/images/TablePagination_gotolast@2x.png';
import TABLE_PAGINATION_prev from '@assets/images/TablePagination_prev@2x.png';
import TABLE_PAGINATION_next from '@assets/images/TablePagination_next@2x.png';
import PURCHASEHISTORY_view from '@assets/images/PurchaseHistory_viewicon@2x.png';
import PURCHASEHISTORY_disabledView from '@assets/images/PurchaseHistory_disabledViewicon@2x.png';
import PURCHASEHISTORY_save from '@assets/images/PurchaseHistory_saveicon@2x.png';
import PURCHASEHISTORY_disabledSave from '@assets/images/PurchaseHistory_disabledSave@2x.png';
import FAQ_arrow from '@assets/images/Faq_arrow@2x.png';
import OnlyPC from '@assets/images/OnlyPC@2x.png';

export const CAROUSEL_MESSAGES = [CarouselM1, CarouselM2, CarouselM3];
export const CAROUSEL_IMAGES = [Carousel1, Carousel2, Carousel3];

export const STARTAG = {
  filtersearch: STARTAG__tablefiltersearh,
};

export const TOPACCOUNTS_ICONS = {
  score: TOPACCOUNTS_ICONS_score,
  like: TOPACCOUNTS_ICONS_like,
  follower: TOPACCOUNTS_ICONS_follower,
  post: TOPACCOUNTS_ICONS_post,
};

export const HOTHASHTAG_ICONS = {
  increase: HOTHASHTAG_ICONS_increase,
  daily: HOTHASHTAG_ICONS_daily,
  amount: HOTHASHTAG_ICONS_amount,
};

export const MAININITTEMPALTE_LOGOS = {
  startag: MAININITTEMPLATE_LOGO_startag,
  exposedaccount: MAININITTEMPLATE_LOGO_exposedaccount,
  account: MAININITTEMPLATE_LOGO_account,
  postincrease: MAININITTEMPLATE_LOGO_postincrease,
};

export const MAININITTEMPLATE_INFO_ICONS = {
  targetuser: MAININITTEMPLATE_ICONS_targetuser,
  analysis: MAININITTEMPLATE_ICONS_analysis,
  startag: MAININITTEMPLATE_ICONS_startag,
  account: MAININITTEMPLATE_ICONS_account,
  exposedaccount: MAININITTEMPLATE_ICONS_exposedaccount,
  postincrease: MAININITTEMPLATE_ICONS_postincrease,
};

export const SMALLPUBLICBOX_ICONS = {
  exposedaccount_date: SMALLPUBLICBOX_ICONS_exposedaccount_date,
  exposedaccount_hashtag: SMALLPUBLICBOX_ICONS_exposedaccount_hashtag,
  exposedaccount_post: SMALLPUBLICBOX_ICONS_exposedaccount_post,
  account_post: SMALLPUBLICBOX_ICONS_account_post,
  postincrease_monthlyincrease:
    SMALLPUBLICBOX_ICONS_postincrease_monthlyincrease,
};

export const TABLE_PAGINATION = {
  gotofirst: TABLE_PAGINATION_gotofirst,
  gotolast: TABLE_PAGINATION_gotolast,
  prev: TABLE_PAGINATION_prev,
  next: TABLE_PAGINATION_next,
};

export const PURCHASEHISTORY = {
  view: PURCHASEHISTORY_view,
  disabledView: PURCHASEHISTORY_disabledView,
  save: PURCHASEHISTORY_save,
  disabledSave: PURCHASEHISTORY_disabledSave,
};

export const FAQ = {
  arrow: FAQ_arrow,
};
export const ETC = {
  onlypc: OnlyPC,
};
