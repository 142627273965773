import { useHistory } from 'react-router-dom';
import { useTypedDispatch } from '@features/rootStore';
import { useRefreshTokenMutation } from '@features/api';
import { updateAccessToken, logout } from '@features/auth';
import { deleteOrderedItem } from '@features/service';

export const useTokenRefresh = () => {
  const dispatch = useTypedDispatch();
  const history = useHistory();
  const [refreshToken] = useRefreshTokenMutation({});

  const handleRefreshToken = () => {
    refreshToken({})
      .unwrap()
      .then(data => {
        dispatch(updateAccessToken(data.access_token));
      })
      .catch(() => {
        dispatch(deleteOrderedItem());
        dispatch(logout());
        history.push('/');
      });
  };
  return {
    refresh: handleRefreshToken,
  };
};
