import { ButtonHTMLAttributes } from 'react';
import * as S from './styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  style?: Record<string, string>;
  value: string;
}

export const PublicThirdButton = ({ style, value, ...props }: Props) => {
  return (
    <S.PublicThirdButton style={style} {...props}>
      {value}
    </S.PublicThirdButton>
  );
};
