import styled from 'styled-components';
import background from '@assets/images/MainInit_background@2x.png';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1560px;
  height: 100vh;
  background-image: url(${background});
  background-size: cover;
`;
export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Infos = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
`;
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Hashtags = styled.ul`
  margin-top: 20px;
  list-style: none;
  & > li {
    margin-bottom: 10px;
    font-size: 17px;
    color: #555;
  }
`;
export const Hashtag = styled.li`
  & > a {
    &:hover {
      font-weight: bold;
    }
  }
`;
export const Pagination = styled.div<{
  isFirstPage?: boolean;
  isLastPage?: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 25%;
  & > .page {
    margin-right: 10px;
    font-size: 13px;
    color: #a0a5b7;
    & > span {
      color: #000;
    }
  }
`;
export const ExposedHashtags = styled.div`
  height: 100%;
  font-size: 18px;
  color: #fff;
`;
export const AccountSuitability = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  & > .suitability {
    padding: 8px 0 21px 0;
    font-family: SCDream;
    font-weight: 500;
    font-size: 38px;
    color: #4a5bfd;
  }
  & > .commentContainer {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 550px;
    & > .bulb {
      width: 75px;
      height: 75px;
    }
  }
`;

export const DetailSuitability = styled.div`
  display: flex;
  margin-top: 40px;
  & > .detailContainer {
    width: 100%;
    &:not(:last-child) {
      border-right: 2px solid #f2f2f2;
    }
    & > .title {
      padding-left: 20px;
      font-weight: 700;
      font-size: 20px;
    }
    & > .content {
      display: flex;
      flex-direction: column;
      margin: 0 34px;
      margin-top: 40px;
      & > .img {
        display: flex;
        justify-content: center;
        padding: 10px 4px;
      }
      & > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 15px;
        color: #555555;
        & > .stress {
          font-weight: 700;
          color: #8d97ff;
        }
      }
    }
  }
`;
