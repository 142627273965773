import { useRef } from 'react';
import { createPortal } from 'react-dom';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal_close as CloseButton } from '@assets/svgs';
import { nanoid } from 'nanoid';
import { Gap } from '@atoms/utils';
import {
  Backdrop,
  Wrapper,
  StyledModal,
  Messages,
  Message,
  SubMessage,
  Buttons,
  FirstButton,
  SecondButton,
} from './Modal.style';

interface Props {
  isShown: boolean;
  modalContent: {
    onFirstButtonClick?: (recipient?: string) => void;
    onSecondButtonClick?: () => void;
    message?: string;
    subMessage?: string[];
    isTwoButton?: boolean;
    isFirstButtonDisabled?: boolean;
    isSecondButtonDisabled?: boolean;
    reportRemainText?: string;
    isReportDownload?: boolean;
    firstButtonText?: string;
    secondButtonText?: string;
  };
  onClose?: () => void;
}
export const Modal: FC<Props> = ({ isShown, modalContent, onClose }) => {
  const { t } = useTranslation();
  const reportInputRef = useRef<any>('');

  const handleFirstButtonClick = () => {
    if (!modalContent.isFirstButtonDisabled) {
      // eslint-disable-next-line no-unused-expressions
      onClose && onClose();
      if (modalContent.onFirstButtonClick) {
        if (modalContent.isReportDownload) {
          modalContent.onFirstButtonClick(reportInputRef.current.value);
        } else {
          modalContent.onFirstButtonClick();
        }
      }
    }
  };
  const handleSecondButtonClick = () => {
    if (!modalContent.isSecondButtonDisabled) {
      // eslint-disable-next-line no-unused-expressions
      onClose && onClose();
      if (modalContent.onSecondButtonClick) {
        modalContent.onSecondButtonClick();
      }
    }
  };
  const modal = (
    <>
      <Backdrop onClick={onClose} />
      <Wrapper>
        <StyledModal
          width={modalContent.isTwoButton ? 500 : 440}
          height={modalContent.isReportDownload ? 260 : 218}
        >
          <CloseButton onClick={onClose} />
          <Messages>
            <Message>{modalContent.message}</Message>
            <SubMessage>
              {modalContent.subMessage?.map(msg => (
                <p key={nanoid()}>{msg}</p>
              ))}
            </SubMessage>
          </Messages>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              fontSize: '14px',
              color: '#555555',
            }}
          >
            {modalContent.reportRemainText || ''}
          </div>
          {modalContent.isReportDownload && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
                marginBottom: '30px',
              }}
            >
              <input
                ref={reportInputRef}
                // placeholder="리포트를 받는 업체명을 입력해주세요(생략가능)"
                placeholder={t('translation.REPORT_INPUT_PLACEHOLDER')}
                style={{
                  padding: '13px 17px',
                  width: '302px',
                  height: '44px',
                  fontSize: '14px',
                  borderRadius: '26px',
                  border: '1px solid #E8E8E8',
                  outline: 0,
                }}
              />
            </div>
          )}
          <Buttons>
            <FirstButton
              onClick={handleFirstButtonClick}
              isDisabled={modalContent.isFirstButtonDisabled as boolean}
            >
              {modalContent.firstButtonText}
            </FirstButton>
            {modalContent.isTwoButton && (
              <>
                <Gap width={16} />
                <SecondButton
                  onClick={handleSecondButtonClick}
                  isDisabled={modalContent.isSecondButtonDisabled as boolean}
                >
                  {modalContent.secondButtonText}
                </SecondButton>
              </>
            )}
          </Buttons>
        </StyledModal>
      </Wrapper>
    </>
  );
  const modalDOM = document.getElementById('modal') as HTMLElement;
  return isShown ? createPortal(modal, modalDOM) : null;
};
