import { Route, Switch, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import { Layout } from '@templates/Layout';
import * as Page from '@pages';
import { logout } from '@features/auth';
import { useTypedDispatch } from '@features/rootStore';
import { deleteOrderedItem } from '@features/service';
import { useTokenRefresh } from '@hooks';
import PrivateRoute from './PrivateRoute';
import ConsoleRoute from './ConsoleRoute';
import ErrorRoute from './ErrorRoute';

const Login = loadable(() => import('@pages/Login/Login'));
const Startag = loadable(() => import('@pages/Startag/Startag'));
const ExposedAccount = loadable(
  () => import('@pages/ExposedAccount/ExposedAccount')
);
const Account = loadable(() => import('@pages/Account/Account'));
const PostIncrease = loadable(() => import('@pages/PostIncrease/PostIncrease'));
const TopAccount = loadable(() => import('@pages/TopAccount/TopAccount'));
const HotHashtag = loadable(() => import('@pages/HotHashtag/HotHashtag'));
const Terms = loadable(() => import('@pages/Terms/Terms'));
const ServiceTerms = loadable(() => import('@pages/ServiceTerms/ServiceTerms'));
const PersonTerms = loadable(() => import('@pages/PersonTerms/PersonTerms'));
// const Notice = loadable(() => import('@pages/Notice/Notice'));
// const NoticeDetail = loadable(() => import('@pages/Notice/NoticeDetail'));
// const Faq = loadable(() => import('@pages/Faq/Faq'));
const Manage = loadable(() => import('@pages/Manage/Manage'));
const ChargeHistory = loadable(
  () => import('@pages/ChargeHistory/ChargeHistory')
);
const PurchaseHistory = loadable(
  () => import('@pages/PurchaseHistory/PurchaseHistory')
);
const Email = loadable(() => import('@pages/Email/Email'));

const Root = () => {
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const { refresh } = useTokenRefresh();
  const handleLogout = () => {
    localStorage.removeItem('language');
    localStorage.removeItem('id');
    dispatch(deleteOrderedItem());
    dispatch(logout());
    refresh();
  };

  return (
    <>
      <Switch>
        {location.pathname === '/ordered' ? (
          <Route path={['/ordered']}>
            <Route path="/ordered" exact component={Page.Ordered} />
          </Route>
        ) : (
          <PrivateRoute
            exact
            path={[
              '/startag',
              '/exposedaccount',
              '/account',
              '/postincrease',
              '/topaccount',
              '/hothashtag',
              '/notice/:detailIdx',
              '/notice',
              '/faq',
              '/manage',
              '/history/charge',
              '/history/purchase',
              '/terms',
              '/terms/service',
              '/terms/person',
            ]}
          >
            <Layout onLogout={handleLogout}>
              <Route path="/startag" exact component={Startag} />
              <Route path="/exposedaccount" exact component={ExposedAccount} />
              <Route path="/account" exact component={Account} />
              <Route path="/postincrease" exact component={PostIncrease} />
              <Route path="/topaccount" exact component={TopAccount} />
              <Route path="/hothashtag" exact component={HotHashtag} />
              {/* <Route path="/notice/:detailIdx" exact component={NoticeDetail} />
              <Route path="/notice" exact component={Notice} />
              <Route path="/faq" exact component={Faq} /> */}
              <Route path="/manage" exact component={Manage} />
              <Route path="/history/charge" exact component={ChargeHistory} />
              <Route
                path="/history/purchase"
                exact
                component={PurchaseHistory}
              />
              <Route path="/terms" exact component={Terms} />
              <Route path="/terms/service" exact component={ServiceTerms} />
              <Route path="/terms/person" exact component={PersonTerms} />
            </Layout>
          </PrivateRoute>
        )}

        <Route path={['/email/*']}>
          <Route path="/email/:hashValue" exact component={Email} />
        </Route>

        <ConsoleRoute exact path={['/']}>
          <Route path="/" exact component={Login} />
        </ConsoleRoute>

        <ErrorRoute path="*" />
      </Switch>
    </>
  );
};

export default Root;
