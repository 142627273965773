import type { FC } from 'react';
import * as S from './styles';

interface Props {
  titleIcon?: string;
  title: string | undefined;
  width: number;
  height: number;
  children?: React.ReactNode;
}
export const PublicBoxWithTitle: FC<Props> = ({
  titleIcon,
  title,
  width,
  height,
  children,
}) => {
  return (
    <S.Box width={width} height={height}>
      <S.TitleWrapper>
        {titleIcon && (
          <img
            style={{
              marginRight: '6px',
              width: '26px',
              height: '26px',
            }}
            src={titleIcon}
            alt="titleIcon"
          />
        )}
        <S.Title color="#000000" desktopFontSize="xxl">
          {title}
        </S.Title>
      </S.TitleWrapper>
      {children}
    </S.Box>
  );
};
