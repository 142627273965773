import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { Spinner, Modal, ReportSpinner } from '@atoms/utils';
import { BasicTable, TableOptions } from '@atoms/tables';
import { PublicBoxWithTitle } from '@molecules/boxes/PublicBoxWithTitle';
import { TableTabButtons } from '@molecules/buttons';
import MainTemplate from '@templates/MainTemplate';
import { useGetTopAccountsQuery } from '@features/api';
import {
  useReport,
  useModal,
  useServiceItemCheckApi,
  useMounted,
} from '@hooks';
import { tableCodes as CODE, TOPACCOUNTS_ICONS } from '@constants';
import { addCommaToNumber } from '@utils';

const TopAccount: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { data } = useGetTopAccountsQuery('top');
  const {
    isShown,
    modalContent,
    onModalOpen,
    onModalClose,
    openServiceErrorModal,
  } = useModal();
  const {
    exportTopAccount,
    errorCode: reportErrorCode,
    setErrorCode: setReportErrorCode,
  } = useReport();
  const { checkIsReportDownloaded } = useServiceItemCheckApi();
  const { isMounted } = useMounted();

  const [category, setCategory] = useState<string>('score');
  const [tableData, setTableData] = useState<TableSet | null>(null);

  const topAccountNames: NameSets = [
    {
      id: nanoid(),
      // kr: '인기게시물',
      kr: t('translation.POPULAR_POSTING'),
      en: 'score',
    },
    {
      id: nanoid(),
      // kr: '좋아요 수',
      kr: t('translation.LIKE_COUNT'),
      en: 'like_average',
    },
    {
      id: nanoid(),
      // kr: '팔로워 수',
      kr: t('translation.FOLLOWER_COUNT'),
      en: 'follower',
    },
    {
      id: nanoid(),
      // kr: '포스트 수',
      kr: t('translation.POST_COUNT'),
      en: 'post',
    },
  ];

  const topAccountTable: TableSets = {
    score: {
      tableCode: CODE.TE,
      // title: 'TOP 인기게시물 노출 계정',
      title: t('translation.TOP_POPULAR_POSTING_EXPOSURE_ACCOUNT'),
      titleIcon: TOPACCOUNTS_ICONS.score,
      columns: [
        t('translation.TABLE_RANKING'), // 순위
        `*${t('translation.TABLE_EXPOSURE_SCORE')}`, // '*노출점수'
        t('translation.TABLE_ACCOUNT'), // '계정'
        t('translation.TABLE_FOLLOWER'), // '팔로워'
        t('translation.TABLE_FOLLOW'), // '팔로우'
        t('translation.TABLE_POST'), // '포스트'
        t('translation.TABLE_DAY_POSTING_COUNT'), // '일 포스팅 횟수'
        t('translation.TABLE_AVERAGE_LIKE'), // '평균 좋아요'
      ],
    },
    like_average: {
      tableCode: CODE.TS,
      // title: 'TOP 평균 좋아요 수 계정',
      title: t('translation.TOP_AVERAGE_LIKE_COUNT_ACCOUNT'),
      titleIcon: TOPACCOUNTS_ICONS.like,
      columns: [
        t('translation.TABLE_RANKING'), // 순위
        t('translation.TABLE_ACCOUNT'), // '계정'
        t('translation.TABLE_FOLLOWER'), // '팔로워'
        t('translation.TABLE_FOLLOW'), // '팔로우'
        t('translation.TABLE_POST'), // '포스트'
        t('translation.TABLE_AVERAGE_LIKE'), // '평균 좋아요'
      ],
    },
    follower: {
      tableCode: CODE.TS,
      // title: 'TOP 팔로워 수 계정',
      title: t('translation.TOP_FOLLOWER_COUNT_ACCOUNT'),
      titleIcon: TOPACCOUNTS_ICONS.follower,
      columns: [
        t('translation.TABLE_RANKING'), // 순위
        t('translation.TABLE_ACCOUNT'), // '계정'
        t('translation.TABLE_FOLLOWER'), // '팔로워'
        t('translation.TABLE_FOLLOW'), // '팔로우'
        t('translation.TABLE_POST'), // '포스트'
        t('translation.TABLE_AVERAGE_LIKE'), // '평균 좋아요'
      ],
    },
    post: {
      tableCode: CODE.TS,
      // title: 'TOP 포스트 수 계정',
      title: t('translation.TOP_POST_COUNT_ACCOUNT'),
      titleIcon: TOPACCOUNTS_ICONS.post,
      columns: [
        t('translation.TABLE_RANKING'), // 순위
        t('translation.TABLE_ACCOUNT'), // '계정'
        t('translation.TABLE_FOLLOWER'), // '팔로워'
        t('translation.TABLE_FOLLOW'), // '팔로우'
        t('translation.TABLE_POST'), // '포스트'
        t('translation.TABLE_AVERAGE_LIKE'), // '평균 좋아요'
      ],
    },
  };

  useEffect(() => {
    setTableData(prevState => {
      return {
        ...prevState,
        tableCode: topAccountTable[category].tableCode,
        title: topAccountTable[category].title,
        columns: topAccountTable[category].columns,
        data: data && data[category],
      };
    });
  }, [data, category]);
  useEffect(() => {
    if (isMounted && reportErrorCode !== '') {
      openServiceErrorModal(reportErrorCode);
    }
    setReportErrorCode('');
  }, [reportErrorCode]);

  const handleTabButtonClick = (target: string) => {
    setCategory(target);
  };
  const handleReportSave = async () => {
    const responseData = await checkIsReportDownloaded('top', 0);

    if (responseData) {
      const isReportDownload = true;
      const executedApi = (recipient = '') => {
        exportTopAccount(data.update_date, recipient, {
          score: data.score,
          like_average: data.like_average,
          follower: data.follower,
          post: data.post,
        });
      };
      const message = t('translation.PREMIUM_SERVICE');
      const subMessage = [
        `${t('translation.PREMIUM_SERVICE_TEXT3')} ${addCommaToNumber(
          responseData.price
        )}${t('translation.PREMIUM_SERVICE_TEXT2')}`,
      ];
      const firstButtonText = t('translation.REPORT_SAVE_BUTTON');
      onModalOpen({
        onFirstButtonClick: executedApi,
        isReportDownload,
        message,
        subMessage,
        firstButtonText,
      });
    }
  };

  if (!data) {
    return <Spinner />;
  }

  return (
    <>
      <MainTemplate hasSearchInput={false}>
        <div>
          <TableOptions
            updateDate={data.update_date}
            onSaveReport={handleReportSave}
          />
          <TableTabButtons
            category={category}
            tabNames={topAccountNames}
            onTabButtonClick={handleTabButtonClick}
          />
          <PublicBoxWithTitle
            titleIcon={topAccountTable[category].titleIcon}
            title={topAccountTable[category].title}
            width={1460}
            height={820}
          >
            <BasicTable tableData={tableData} tbodyHeight={710} />
          </PublicBoxWithTitle>
        </div>
      </MainTemplate>
      <ReportSpinner />
      <Modal
        isShown={isShown}
        modalContent={modalContent}
        onClose={onModalClose}
      />
    </>
  );
};
export default TopAccount;
