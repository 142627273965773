import { createPortal } from 'react-dom';
import SyncLoader from 'react-spinners/SyncLoader';
import { useTypedSelector, RootState } from '@features/rootStore';

const ReportSpinner = () => {
  const { isReportDownload } = useTypedSelector(
    ({ rootReducer }: RootState) => ({
      isReportDownload: rootReducer.serviceReducers.isReportDownload,
    })
  );
  const reportSpinnerDOM = document.getElementById(
    'reportSpinner'
  ) as HTMLElement;
  const reportSpinner = (
    <div
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
        background: 'rgba(0, 0, 0, 0.7)',
        zIndex: 40000,
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <SyncLoader color="#8D97FF" speedMultiplier={0.5} size={80} />
      </div>
    </div>
  );

  return isReportDownload
    ? createPortal(reportSpinner, reportSpinnerDOM)
    : null;
};

export default ReportSpinner;
