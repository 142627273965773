import styled from 'styled-components';

export const LightText = styled.p<TextStyle>`
  font-weight: 300;
  font-size: ${({ desktopFontSize, theme }) =>
    desktopFontSize && `${theme.fontSize[desktopFontSize]}px`};
  color: ${({ color, theme }) =>
    color === 'error'
      ? theme.color.functional.error
      : theme.color[color as string]};
`;
