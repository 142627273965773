import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import { increaseServiceApiCallDetectCount } from '@features/auth';
import { RootState } from '@features/rootStore';

export const AUTH_API_REDUCER_KEY = 'authApi';
export const USERS_API_REDUCER_KEY = 'usersApi';
export const SERVICE_API_REDUCER_KEY = 'serviceApi';
export const LOOKUP_API_REDUCER_KEY = 'lookupApi';
export const NOTICE_API_REDUCER_KEY = 'noticeApi';
export const REPORT_API_REDUCER_KEY = 'reportApi';
export const FAQ_API_REDUCER_KEY = 'faqApi';

export const authApi = createApi({
  reducerPath: AUTH_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}auth/`,
  }),
  tagTypes: ['Auth'],
  endpoints: build => ({
    login: build.mutation({
      query: payload => ({
        url: 'login',
        method: 'POST',
        body: payload,
      }),
    }),
    emailAuthenthication: build.query({
      query: ({ hashValue }) => ({
        url: `email_authentication/${hashValue}`,
        method: 'GET',
      }),
    }),
    resendEmailAuthentication: build.mutation({
      query: payload => ({
        url: 'resend_authentication',
        method: 'POST',
        body: payload,
      }),
    }),
    resendExpiredEmailAuthentication: build.mutation({
      query: payload => ({
        url: 'expire_resend_authentication',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const usersApi = createApi({
  reducerPath: USERS_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}user/`,
    prepareHeaders: (headers, { getState }) => {
      const {
        rootReducer: {
          authReducers: { accessToken },
        },
      } = getState() as RootState;

      headers.set('Authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  tagTypes: ['Users'],
  endpoints: build => ({
    chargeHistory: build.query({
      query: () => ({ url: 'mypage/deposit', method: 'GET' }),
    }),
    purchaseHistory: build.mutation({
      query: () => ({
        url: 'mypage/order',
        method: 'GET',
      }),
      invalidatesTags: ['Users'],
    }),
    refreshToken: build.mutation({
      query: () => ({ url: 'refresh_token', method: 'GET' }),
      invalidatesTags: ['Users'],
    }),
    sidebarInfo: build.mutation({
      query: () => ({ url: 'sidebar_info', method: 'GET' }),
      invalidatesTags: ['Users'],
    }),
    getAccountInfo: build.query({
      query: () => ({ url: 'mypage/account', method: 'GET' }),
    }),
  }),
});

const baseQueryOnServices = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}services/`,
  prepareHeaders: (headers, { getState }) => {
    const {
      rootReducer: {
        authReducers: { accessToken },
      },
    } = getState() as RootState;

    headers.set('Authorization', `Bearer ${accessToken}`);
    return headers;
  },
});
const baseQueryOnServicesWithIntercept: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result: QueryReturnValue<any, FetchBaseQueryError, FetchBaseQueryMeta> =
    await baseQueryOnServices(args, api, extraOptions);

  api.dispatch(increaseServiceApiCallDetectCount());
  return result;
};

export const serviceApi = createApi({
  reducerPath: SERVICE_API_REDUCER_KEY,
  baseQuery: baseQueryOnServicesWithIntercept,
  tagTypes: ['Services'],
  endpoints: build => ({
    getStartagFreeItem: build.mutation({
      query: ({ hashtag }) => ({
        url: `free/star_hashtag/${hashtag}`,
        method: 'GET',
      }),
    }),
    getNewChargedItem: build.mutation({
      query: ({ category, hashtag }) => ({
        url: `charge/hashtag/${category}/${hashtag}`,
        method: 'GET',
      }),
      invalidatesTags: ['Services'],
    }),
    getNewAccountItem: build.mutation({
      query: ({ username }) => ({
        url: `/charge/account/${username}`,
        method: 'GET',
      }),
      invalidatesTags: ['Services'],
    }),
    getTopAccounts: build.query({
      query: type => ({ url: `free/${type}`, method: 'GET' }),
    }),
    getHotHashtags: build.query({
      query: type => ({ url: `free/${type}`, method: 'GET' }),
    }),
  }),
});

const baseQueryOnReport = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}services/report/`,
  prepareHeaders: (headers, { getState }) => {
    const {
      rootReducer: {
        authReducers: { accessToken },
      },
    } = getState() as RootState;

    headers.set('Authorization', `Bearer ${accessToken}`);
    return headers;
  },
});
const baseQueryOnReportWithIntercept: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result: QueryReturnValue<any, FetchBaseQueryError, FetchBaseQueryMeta> =
    await baseQueryOnReport(args, api, extraOptions);
  api.dispatch(increaseServiceApiCallDetectCount());
  return result;
};

export const reportApi = createApi({
  reducerPath: REPORT_API_REDUCER_KEY,
  baseQuery: baseQueryOnReportWithIntercept,
  tagTypes: ['Report'],
  endpoints: build => ({
    isDownloaded: build.mutation({
      query: ({ service, no }) => {
        return {
          url: 'is_downloaded',
          method: 'POST',
          body: {
            service,
            no,
          },
        };
      },
    }),
    createReport: build.mutation({
      query: ({ service, form, keyword = null, recipient = null }) => {
        let url = `create/${service}`;

        if (keyword !== null && recipient !== null) {
          url = `create/${service}?recipient_name=${recipient}&keyword=${keyword}`;
        } else if (keyword) {
          url = `create/${service}?keyword=${keyword}`;
        } else if (recipient) {
          url = `create/${service}?recipient_name=${recipient}`;
        }
        return {
          url,
          responseHandler: response => {
            if (response.status >= 400) return response.json();
            return response.blob();
          },
          method: 'POST',
          body: form,
        };
      },
      transformResponse: (returnValue: any) => {
        return returnValue;
      },
    }),
    saveByOrderIdx: build.mutation({
      query: ({ order_idx }) => {
        return {
          url: 'status',
          method: 'POST',
          body: {
            order_idx,
          },
        };
      },
    }),
    reorder: build.mutation({
      query: ({ order_idx }) => {
        return {
          url: 'reorder',
          method: 'POST',
          responseHandler: response => {
            if (response.status >= 400) return response.json();
            return response.blob();
          },
          body: {
            order_idx,
          },
        };
      },
    }),
  }),
});

export const lookupApi = createApi({
  reducerPath: LOOKUP_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}lookup/`,
    prepareHeaders: (headers, { getState }) => {
      const {
        rootReducer: {
          authReducers: { accessToken },
        },
      } = getState() as RootState;

      headers.set('Authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  tagTypes: ['Lookup'],
  endpoints: build => ({
    getExistChargedItem: build.mutation({
      query: payload => ({
        url: '/get_data',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Lookup'],
    }),
    checkIsOrdered: build.mutation({
      query: payload => ({
        url: '/is_ordered',
        method: 'POST',
        body: payload,
      }),
    }),
    getPurchaseHistoryItem: build.mutation({
      query: ({ idx, type, price, add_date }) => ({
        url: '/mypage',
        method: 'POST',
        body: {
          idx,
          type,
          price,
          add_date,
        },
      }),
      invalidatesTags: ['Lookup'],
    }),
  }),
});

export const noticeApi = createApi({
  reducerPath: NOTICE_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}notice/`,
    prepareHeaders: (headers, { getState }) => {
      const {
        rootReducer: {
          authReducers: { accessToken },
        },
      } = getState() as RootState;

      headers.set('Authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  tagTypes: ['Notice'],
  endpoints: build => ({
    getNoticeList: build.query({
      query: () => ({ url: '/', method: 'GET' }),
    }),
    getNoticeDetail: build.query({
      query: ({ detailIdx }) => ({ url: `detail/${detailIdx}`, method: 'GET' }),
    }),
  }),
});
export const faqApi = createApi({
  reducerPath: FAQ_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}faq/`,
    prepareHeaders: (headers, { getState }) => {
      const {
        rootReducer: {
          authReducers: { accessToken },
        },
      } = getState() as RootState;

      headers.set('Authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  tagTypes: ['Faq'],
  endpoints: build => ({
    getFaqList: build.query({
      query: () => ({ url: '/', method: 'GET' }),
    }),
  }),
});

export const {
  useLoginMutation,
  useEmailAuthenthicationQuery,
  useResendEmailAuthenticationMutation,
  useResendExpiredEmailAuthenticationMutation,
} = authApi;
export const {
  useChargeHistoryQuery,
  usePurchaseHistoryMutation,
  useRefreshTokenMutation,
  useSidebarInfoMutation,
  useGetAccountInfoQuery,
} = usersApi;
export const {
  useGetTopAccountsQuery,
  useGetHotHashtagsQuery,
  useGetNewChargedItemMutation,
  useGetNewAccountItemMutation,
  useGetStartagFreeItemMutation,
} = serviceApi;
export const {
  useCheckIsOrderedMutation,
  useGetExistChargedItemMutation,
  useGetPurchaseHistoryItemMutation,
} = lookupApi;
export const { useGetNoticeListQuery, useGetNoticeDetailQuery } = noticeApi;
export const { useGetFaqListQuery } = faqApi;
export const {
  useIsDownloadedMutation,
  useCreateReportMutation,
  useSaveByOrderIdxMutation,
  useReorderMutation,
} = reportApi;
