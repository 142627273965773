import styled, { css, CSSProp } from 'styled-components';
import { tableCodes as CODE } from '@constants';

export function columnGenerator(code: string, cell: string) {
  const columns: { [key: string]: CSSProp } = {};
  columns[CODE.SF] = css`
    & > tr {
      & > ${cell}:nth-child(1) {
        width: 70px;
      }
      & > ${cell}:nth-child(2) {
        width: 215px;
      }
      & > ${cell}:nth-child(3) {
        width: 215px;
      }
      & > ${cell}:nth-child(4) {
        width: 170px;
      }
    }
  `;
  columns[CODE.SFV] = css`
    & > tr {
      & > ${cell}:nth-child(1) {
        width: 70px;
      }
      & > ${cell}:nth-child(2) {
        width: 70px;
      }
      & > ${cell}:nth-child(3) {
        width: 185px;
      }
      & > ${cell}:nth-child(4) {
        width: 185px;
      }
      & > ${cell}:nth-child(5) {
        width: 160px;
      }
    }
  `;
  columns[CODE.TE] = css`
    & > tr {
      & > ${cell}:nth-child(1) {
        width: 120px;
      }
      & > ${cell}:nth-child(2) {
        width: 160px;
      }
      & > ${cell}:nth-child(3) {
        width: 240px;
      }
      & > ${cell}:nth-child(4) {
        width: 180px;
      }
      & > ${cell}:nth-child(5) {
        width: 180px;
      }
      & > ${cell}:nth-child(6) {
        width: 180px;
      }
      & > ${cell}:nth-child(7) {
        width: 180px;
      }
      & > ${cell}:nth-child(8) {
        width: 180px;
      }
    }
  `;
  columns[CODE.TE] = css`
    & > tr {
      & > ${cell}:nth-child(1) {
        width: 120px;
      }
      & > ${cell}:nth-child(2) {
        width: 160px;
      }
      & > ${cell}:nth-child(3) {
        width: 240px;
      }
      & > ${cell}:nth-child(4) {
        width: 180px;
      }
      & > ${cell}:nth-child(5) {
        width: 180px;
      }
      & > ${cell}:nth-child(6) {
        width: 180px;
      }
      & > ${cell}:nth-child(7) {
        width: 180px;
      }
      & > ${cell}:nth-child(8) {
        width: 180px;
      }
    }
  `;
  columns[CODE.TS] = css`
    & > tr {
      & > ${cell}:nth-child(1) {
        width: 120px;
      }
      & > ${cell}:nth-child(2) {
        width: 320px;
      }
      & > ${cell}:nth-child(3) {
        width: 245px;
      }
      & > ${cell}:nth-child(4) {
        width: 245px;
      }
      & > ${cell}:nth-child(5) {
        width: 245px;
      }
      & > ${cell}:nth-child(6) {
        width: 245px;
      }
    }
  `;
  columns[CODE.HT] = css`
    & > tr {
      & > ${cell}:nth-child(1) {
        width: 120px;
      }
      & > ${cell}:nth-child(2) {
        width: 760px;
      }
      & > ${cell}:nth-child(3) {
        width: 540px;
      }
    }
  `;
  columns[CODE.ESV] = css`
    & > tr {
      display: block;
      & > ${cell}:nth-child(1) {
        width: 50px;
      }
      & > ${cell}:nth-child(2) {
        width: 78px;
      }
      & > ${cell}:nth-child(3) {
        width: 180px;
      }
      & > ${cell}:nth-child(4) {
        width: 88px;
      }
      & > ${cell}:nth-child(5) {
        width: 88px;
      }
      & > ${cell}:nth-child(6) {
        width: 88px;
      }
      & > ${cell}:nth-child(7) {
        width: 98px;
      }
    }
  `;
  columns[CODE.EN] = css`
    & > tr {
      & > ${cell}:nth-child(1) {
        width: 50px;
      }
      & > ${cell}:nth-child(2) {
        width: 218px;
      }
      & > ${cell}:nth-child(3) {
        width: 136px;
      }
      & > ${cell}:nth-child(4) {
        width: 136px;
      }
      & > ${cell}:nth-child(5) {
        width: 336px;
      }
      & > ${cell}:nth-child(6) {
        width: 136px;
      }
      & > ${cell}:nth-child(7) {
        width: 136px;
      }
      & > ${cell}:nth-child(8) {
        width: 136px;
      }
      & > ${cell}:nth-child(9) {
        width: 136px;
      }
    }
  `;
  return columns[code];
}

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cecece;
  background: #fff;
  margin-left: 10px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  &:hover {
    background: #f2f2f2;
  }
`;
export const Table = styled.table`
  position: relative;
  width: 100%;
  height: fit-content;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
`;
export const THead = styled.thead<{ code: string }>`
  display: block;
  padding-top: 10px;
  min-height: 40px;
  background-color: #f9fbff;
  ${({ code }) => columnGenerator(code, 'th')}

  > tr {
    background-color: #f9fbff;
  }
`;
export const TBody = styled.tbody<{ code: string; height: number }>`
  display: block;
  overflow: auto;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  overflow-y: overlay;
  ${({ code }) => columnGenerator(code, 'td')}
`;
export const TR = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: #f9fbff;
    font-weight: bold;
  }
`;
export const TH = styled.th`
  font-weight: 700;
  font-size: 18px;
  color: #555;
`;
export const TD = styled.td`
  text-align: center;
  height: 40px;
  border-bottom: 1px dashed #e8e8e8;
  font-weight: inherit;
  font-size: 15px;
  color: #555;
  word-break: break-word;
  overflow-wrap: break-word;
`;
