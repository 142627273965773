import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServiceState } from '@features/models/states';

const initialState: ServiceState = {
  orderedItem: null,
  isReportDownload: false,
};

const serviceSlice = createSlice({
  name: 'serviceSlice',
  initialState,
  reducers: {
    orderedItem(state: ServiceState, action: PayloadAction<string | null>) {
      state.orderedItem = action.payload;
    },
    deleteOrderedItem(state: ServiceState) {
      state.orderedItem = null;
    },
    changeIsReportDownloadState(state: ServiceState) {
      state.isReportDownload = !state.isReportDownload;
    },
  },
});
export const { orderedItem, deleteOrderedItem, changeIsReportDownloadState } =
  serviceSlice.actions;
export const serviceReducers = serviceSlice.reducer;
