import type { FC } from 'react';
import { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { BasicTable, TableOptions } from '@atoms/tables';
import { PublicBox } from '@atoms/boxes';
import { PublicSearchInput } from '@atoms/inputs';
import { PublicText } from '@atoms/texts';
import {
  Gap,
  FlexContainer,
  Modal,
  Spinner,
  ReportSpinner,
} from '@atoms/utils';
import { SmallPublicBoxWithIcon, PublicBoxWithTitle } from '@molecules/boxes';
import MainTemplate from '@templates/MainTemplate';
import {
  tableCodes as CODE,
  SMALLPUBLICBOX_ICONS as SMALLBOXICON,
  MAININITTEMPALTE_LOGOS as LOGO,
  EXPOSEDACCOUNT_DESCRIPTION as DESCRIPTION,
} from '@constants';
import {
  useServiceApi,
  useServiceItemCheckApi,
  useModal,
  useMounted,
  useReport,
} from '@hooks';
import { addCommaToNumber } from '@utils';
import * as S from './ExposedAccount.style';

// const tableInit: TableSets = {
//   top_id_7: {
//     tableCode: CODE.ESV,
//     title: '최근 7일 인기게시물 계정 순위',
//     columns: [
//       'NO',
//       '*점수',
//       '계정',
//       '팔로워',
//       '팔로우',
//       '포스트',
//       '평균 좋아요',
//     ],
//   },
//   top_id_30: {
//     tableCode: CODE.ESV,
//     title: '최근 30일 인기게시물 계정 순위',
//     columns: [
//       'NO',
//       '*점수',
//       '계정',
//       '팔로워',
//       '팔로우',
//       '포스트',
//       '평균 좋아요',
//     ],
//   },
//   top_id_90: {
//     tableCode: CODE.ESV,
//     title: '최근 90일 인기게시물 계정 순위',
//     columns: [
//       'NO',
//       '*점수',
//       '계정',
//       '팔로워',
//       '팔로우',
//       '포스트',
//       '평균 좋아요',
//     ],
//   },
//   top_id_180: {
//     tableCode: CODE.ESV,
//     title: '최근 180일 인기게시물 계정 순위',
//     columns: [
//       'NO',
//       '*점수',
//       '계정',
//       '팔로워',
//       '팔로우',
//       '포스트',
//       '평균 좋아요',
//     ],
//   },
//   top_like: {
//     tableCode: CODE.EN,
//     title: '해시태그 TOP 좋아요 게시물',
//     columns: [
//       'NO',
//       '포스팅 날짜',
//       '좋아요',
//       '댓글',
//       '계정',
//       '팔로워',
//       '팔로우',
//       '포스트',
//       '평균 좋아요',
//     ],
//   },
//   top_comment: {
//     tableCode: CODE.EN,
//     title: '해시태그 TOP 댓글 게시물',
//     columns: [
//       'NO',
//       '포스팅 날짜',
//       '좋아요',
//       '댓글',
//       '계정',
//       '팔로워',
//       '팔로우',
//       '포스트',
//       '평균 좋아요',
//     ],
//   },
// };

const ExposedAccount: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const searchedHashtagRef = useRef<string>('');

  const {
    isShown,
    modalContent,
    onModalOpen,
    onModalClose,
    openServiceErrorModal,
  } = useModal();
  const {
    exportExposedAccount,
    errorCode: reportErrorCode,
    setErrorCode: setReportErrorCode,
  } = useReport();
  const { checkKeywordIsOrdered, checkIsReportDownloaded } =
    useServiceItemCheckApi();
  const { getNew, getNewChargedItemState, getExist, getExistChargedItemState } =
    useServiceApi();
  const { isMounted } = useMounted();

  const [inputHashtag, setInputHashtag] = useState<string>('');
  const [serviceData, setServiceData] = useState<any>({
    updateDate: '',
    basicInfo: [
      {
        iconName: SMALLBOXICON.exposedaccount_date,
        label: t('translation.INQUIRY_DATE'),
        width: 460,
        height: 110,
      },
      {
        iconName: SMALLBOXICON.exposedaccount_hashtag,
        label: t('translation.HASHTAG'),
        width: 460,
        height: 110,
      },
      {
        iconName: SMALLBOXICON.exposedaccount_post,
        label: t('translation.POSTING_COUNT'),
        width: 460,
        height: 110,
      },
    ],
    tableData: {
      top_id_7: {
        tableCode: CODE.ESV,
        // title: '최근 7일 인기게시물 계정 순위',
        title: t('translation.RECENT_WEEK_POPULAR_POSTING_ACCOUNT_RANKING'),
        columns: [
          'NO',
          `*${t('translation.TABLE_SCORE')}`,
          t('translation.TABLE_ACCOUNT'),
          t('translation.TABLE_FOLLOWER'),
          t('translation.TABLE_FOLLOW'),
          t('translation.TABLE_POST'),
          t('translation.TABLE_AVERAGE_LIKE'),
        ],
      },
      top_id_30: {
        tableCode: CODE.ESV,
        // title: '최근 30일 인기게시물 계정 순위',
        title: t('translation.RECENT_MONTH_POPULAR_POSTING_ACCOUNT_RANKING'),
        columns: [
          'NO',
          `*${t('translation.TABLE_SCORE')}`,
          t('translation.TABLE_ACCOUNT'),
          t('translation.TABLE_FOLLOWER'),
          t('translation.TABLE_FOLLOW'),
          t('translation.TABLE_POST'),
          t('translation.TABLE_AVERAGE_LIKE'),
        ],
      },
      top_id_90: {
        tableCode: CODE.ESV,
        // title: '최근 90일 인기게시물 계정 순위',
        title: t('translation.RECENT_QUARTER_POPULAR_POSTING_ACCOUNT_RANKING'),
        columns: [
          'NO',
          `*${t('translation.TABLE_SCORE')}`,
          t('translation.TABLE_ACCOUNT'),
          t('translation.TABLE_FOLLOWER'),
          t('translation.TABLE_FOLLOW'),
          t('translation.TABLE_POST'),
          t('translation.TABLE_AVERAGE_LIKE'),
        ],
      },
      top_id_180: {
        tableCode: CODE.ESV,
        // title: '최근 180일 인기게시물 계정 순위',
        title: t(
          'translation.RECENT_HALF_YEAR_POPULAR_POSTING_ACCOUNT_RANKING'
        ),
        columns: [
          'NO',
          `*${t('translation.TABLE_SCORE')}`,
          t('translation.TABLE_ACCOUNT'),
          t('translation.TABLE_FOLLOWER'),
          t('translation.TABLE_FOLLOW'),
          t('translation.TABLE_POST'),
          t('translation.TABLE_AVERAGE_LIKE'),
        ],
      },
      top_like: {
        tableCode: CODE.EN,
        // title: '해시태그 TOP 좋아요 게시물',
        title: t('translation.HASHTAG_TOP_LIKE_POSTING'),
        columns: [
          'NO',
          t('translation.TABLE_POSTING_DATE'),
          t('translation.TABLE_LIKE'),
          t('translation.TABLE_COMMENT'),
          t('translation.TABLE_ACCOUNT'),
          t('translation.TABLE_FOLLOWER'),
          t('translation.TABLE_FOLLOW'),
          t('translation.TABLE_POST'),
          t('translation.TABLE_AVERAGE_LIKE'),
        ],
      },
      top_comment: {
        tableCode: CODE.EN,
        // title: '해시태그 TOP 댓글 게시물',
        title: t('translation.HASHTAG_TOP_COMMENT_POSTING'),
        columns: [
          'NO',
          t('translation.TABLE_POSTING_DATE'),
          t('translation.TABLE_LIKE'),
          t('translation.TABLE_COMMENT'),
          t('translation.TABLE_ACCOUNT'),
          t('translation.TABLE_FOLLOWER'),
          t('translation.TABLE_FOLLOW'),
          t('translation.TABLE_POST'),
          t('translation.TABLE_AVERAGE_LIKE'),
        ],
      },
    },
    reportNo: 0,
  });
  const [itemPrice, setItemPrice] = useState(0);
  const [isTableOptionsGetNewDisabled, setIsTableOptionsGetNewDisabled] =
    useState<boolean>(false);

  useEffect(() => {
    if (searchedHashtagRef.current === '') setInputHashtag('');
  }, [isShown]);
  useEffect(() => {
    if (isMounted && reportErrorCode !== '') {
      openServiceErrorModal(reportErrorCode);
    }
    setReportErrorCode('');
  }, [reportErrorCode]);

  function handleServiceDataSet(responseData: any) {
    const {
      update_date,
      search_date,
      hashtag,
      post,
      top_id_7,
      top_id_30,
      top_id_90,
      top_id_180,
      top_like,
      top_comment,
      no,
    } = responseData;
    setServiceData((prev: any) => {
      return {
        updateDate: update_date,
        basicInfo: prev.basicInfo.map((info: any, i: number) => {
          if (i === 0) return { ...info, value: search_date };
          if (i === 1) return { ...info, value: hashtag };
          return { ...info, value: post };
        }),
        tableData: {
          top_id_7: { ...prev.tableData.top_id_7, data: top_id_7 },
          top_id_30: { ...prev.tableData.top_id_30, data: top_id_30 },
          top_id_90: { ...prev.tableData.top_id_90, data: top_id_90 },
          top_id_180: { ...prev.tableData.top_id_180, data: top_id_180 },
          top_like: { ...prev.tableData.top_like, data: top_like },
          top_comment: {
            ...prev.tableData.top_comment,
            data: top_comment,
          },
        },
        reportNo: no,
      };
    });
  }

  const handleSearchButtonClick = async () => {
    if (inputHashtag === '') return;
    try {
      if (
        inputHashtag.includes('\\') ||
        inputHashtag.includes('/') ||
        inputHashtag.includes('?') ||
        inputHashtag.includes('!') ||
        inputHashtag.includes('@') ||
        inputHashtag.includes('#')
      ) {
        throw Error;
      }
      const { status, order_info, price } = await checkKeywordIsOrdered(
        'exposed_account',
        inputHashtag
      );
      setItemPrice(price);
      onModalOpen({
        onFirstButtonClick: async () => {
          const responseData = await getNew('exposed_account', inputHashtag);
          if (responseData) {
            if (responseData.status >= 400) {
              openServiceErrorModal(responseData.data.code);
            } else {
              searchedHashtagRef.current = inputHashtag;
              handleServiceDataSet(responseData);
              setIsTableOptionsGetNewDisabled(true);
            }
          }
        },
        onSecondButtonClick: async () => {
          const responseData = await getExist(order_info);
          searchedHashtagRef.current = inputHashtag;
          handleServiceDataSet(responseData);
          setIsTableOptionsGetNewDisabled(status === -1);
        },
        message:
          status === -1
            ? t('translation.ALREADY_PURCHASE_PRODUCTION_TITLE')
            : t('translation.PREMIUM_SERVICE'),
        subMessage: [
          status === -1
            ? t('translation.ALREADY_NEW_ANALYSIS_RESULT_PURCHASE_TITLE')
            : `${t(
                'translation.ALREADY_NEW_ANALYSIS_RESULT_PURCHASE_TEXT'
              )} ${addCommaToNumber(price)}${t(
                'translation.PREMIUM_SERVICE_TEXT2'
              )}`,
        ],
        isTwoButton: true,
        isFirstButtonDisabled: status === -1,
        isSecondButtonDisabled: order_info === '0',
        firstButtonText: t('translation.NEW_ANALYSIS_RESULT_INQUIRY'),
        secondButtonText: t('translation.ORIGIN_ANALYSIS_RESULT_INQUIRY'),
      });
    } catch (e) {
      openServiceErrorModal();
    }
  };

  const handleGetNewChargedItemButtonClick = () => {
    if (isTableOptionsGetNewDisabled) return;
    onModalOpen({
      onFirstButtonClick: async () => {
        const responseData = await getNew('exposed_account', inputHashtag);
        if (responseData) {
          if (responseData.status >= 400) {
            openServiceErrorModal(responseData.data.code);
          } else {
            searchedHashtagRef.current = inputHashtag;
            handleServiceDataSet(responseData);
            setIsTableOptionsGetNewDisabled(true);
          }
        }
      },
      message: t('translation.PREMIUM_SERVICE'),
      subMessage: [
        `${t('translation.PREMIUM_SERVICE_TEXT1')} ${addCommaToNumber(
          itemPrice
        )}${t('translation.PREMIUM_SERVICE_TEXT2')}`,
      ],
      firstButtonText: t('translation.NEW_DATA_ANALYSIS_BUTTON'),
    });
  };

  const handleReportSave = async () => {
    const responseData = await checkIsReportDownloaded(
      'exposed_account',
      serviceData.reportNo
    );
    if (responseData) {
      const isReportDownload = true;
      const executedApi = (recipient = '') => {
        exportExposedAccount(
          searchedHashtagRef.current,
          serviceData.updateDate,
          recipient,
          {
            top_id_7: serviceData.tableData.top_id_7.data,
            top_id_30: serviceData.tableData.top_id_30.data,
            top_id_90: serviceData.tableData.top_id_90.data,
            top_id_180: serviceData.tableData.top_id_180.data,
            top_like: serviceData.tableData.top_like.data,
            top_comment: serviceData.tableData.top_comment.data,
          }
        );
      };
      const message = t('translation.PREMIUM_SERVICE');
      const subMessage = [
        `${t('translation.PREMIUM_SERVICE_TEXT3')} ${addCommaToNumber(
          responseData.price
        )}${t('translation.PREMIUM_SERVICE_TEXT2')}`,
      ];
      const firstButtonText = t('translation.REPORT_SAVE_BUTTON');
      onModalOpen({
        onFirstButtonClick: executedApi,
        isReportDownload,
        message,
        subMessage,
        firstButtonText,
      });
    }
  };

  const onConvertLanguage = () => {
    const originList = {
      ...DESCRIPTION,
      title: t('translation.POPULAR_POSTING_EXPOSURE_ACCOUNT_ANALYSIS'),
    };

    const res = originList.info.map((obj: any) => {
      let title = '';
      let content = '';

      if (obj.title === '인기게시물/노출 계정 분석이란?') {
        title = t(
          'translation.POPULAR_POSTING_EXPOSURE_ACCOUNT_ANALYSIS_TITLE1'
        );
        content = t(
          'translation.POPULAR_POSTING_EXPOSURE_ACCOUNT_ANALYSIS_TITLE1_DESCRIPTION1'
        );
      }

      if (obj.title === '추천 사용자') {
        title = t(
          'translation.POPULAR_POSTING_EXPOSURE_ACCOUNT_ANALYSIS_TITLE2'
        );
        content = t(
          'translation.POPULAR_POSTING_EXPOSURE_ACCOUNT_ANALYSIS_TITLE2_DESCRIPTION1'
        );
      }

      if (obj.title === '분석항목') {
        title = t(
          'translation.POPULAR_POSTING_EXPOSURE_ACCOUNT_ANALYSIS_TITLE3'
        );
        content = t(
          'translation.POPULAR_POSTING_EXPOSURE_ACCOUNT_ANALYSIS_TITLE3_DESCRIPTION1'
        );
      }

      return {
        ...obj,
        title,
        content,
      };
    });

    return {
      ...originList,
      info: res,
    };
  };

  if (getNewChargedItemState.isLoading || getExistChargedItemState.isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {serviceData.updateDate &&
      serviceData.basicInfo &&
      serviceData.tableData ? (
        <MainTemplate
          hasSearchInput
          onInputChange={e => setInputHashtag(e.target.value)}
          inputValue={inputHashtag}
          onSearchClick={handleSearchButtonClick}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleSearchButtonClick();
            }
          }}
        >
          <TableOptions
            isGetNewHave
            updateDate={serviceData.updateDate}
            onGetNewClick={handleGetNewChargedItemButtonClick}
            onSaveReport={handleReportSave}
            isGetNewDisabled={isTableOptionsGetNewDisabled}
          />
          <FlexContainer>
            {serviceData.basicInfo.map((info: any, i: number) => (
              <SmallPublicBoxWithIcon
                key={nanoid()}
                iconName={info.iconName}
                width={info.width}
                height={info.height}
                value={
                  typeof info.value === 'number'
                    ? addCommaToNumber(info.value.toString())
                    : i === 0
                    ? info.value?.substring(0, 10)
                    : info.value
                }
                label={info.label}
              />
            ))}
          </FlexContainer>
          <Gap height={40} />
          <FlexContainer>
            <PublicBoxWithTitle
              title={serviceData.tableData?.top_id_7.title}
              width={710}
              height={504}
            >
              <BasicTable
                tableData={serviceData.tableData.top_id_7}
                tbodyHeight={380}
              />
            </PublicBoxWithTitle>
            <PublicBoxWithTitle
              title={serviceData.tableData?.top_id_30.title}
              width={710}
              height={504}
            >
              <BasicTable
                tableData={serviceData.tableData.top_id_30}
                tbodyHeight={380}
              />
            </PublicBoxWithTitle>
            <Gap height={40} />
            <PublicBoxWithTitle
              title={serviceData.tableData?.top_id_90.title}
              width={710}
              height={504}
            >
              <BasicTable
                tableData={serviceData.tableData.top_id_90}
                tbodyHeight={380}
              />
            </PublicBoxWithTitle>
            <PublicBoxWithTitle
              title={serviceData.tableData?.top_id_180.title}
              width={710}
              height={504}
            >
              <BasicTable
                tableData={serviceData.tableData.top_id_180}
                tbodyHeight={380}
              />
            </PublicBoxWithTitle>
            <Gap height={40} />
            <PublicBoxWithTitle
              title={serviceData.tableData?.top_like.title}
              width={1460}
              height={504}
            >
              <BasicTable
                tableData={serviceData.tableData.top_like}
                tbodyHeight={380}
              />
            </PublicBoxWithTitle>
            <Gap height={40} />
            <PublicBoxWithTitle
              title={serviceData.tableData?.top_comment.title}
              width={1460}
              height={504}
            >
              <BasicTable
                tableData={serviceData.tableData.top_comment}
                tbodyHeight={380}
              />
            </PublicBoxWithTitle>
          </FlexContainer>
        </MainTemplate>
      ) : (
        <S.Container>
          <S.Contents>
            <img
              style={{ width: '76px', height: '76px' }}
              src={LOGO.exposedaccount}
              alt="logo"
            />
            <Gap height={28} />
            <PublicText
              style={{ fontSize: '40px', fontWeight: 'bold' }}
              value={t('translation.POPULAR_POSTING_EXPOSURE_ACCOUNT_ANALYSIS')}
            />
            <Gap height={32} />
            <PublicSearchInput
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleSearchButtonClick();
                }
              }}
              onSearchClick={handleSearchButtonClick}
              onInputChange={e => setInputHashtag(e.target.value)}
              placeholder={t('translation.INPUT_PLACEHOLDER_HASHTAG')}
              value={inputHashtag}
              style={{
                width: '748px',
                height: '70px',
                borderRadius: '10px',
                fontSize: '20px',
                inputWidth: '638px',
              }}
            />
            <Gap height={70} />
            <S.Infos>
              {onConvertLanguage().info.map((info, i) => {
                const titles: any[] = info.title.split('/');
                const texts: any[] = info.content.split('/');
                return (
                  <PublicBox
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: '30px 10px',
                    }}
                    width={236}
                    height={250}
                    key={nanoid()}
                  >
                    <S.IconWrapper>
                      <img
                        src={info.iconName}
                        alt="icon"
                        style={{ width: '100%' }}
                      />
                    </S.IconWrapper>
                    {titles.map(text => (
                      <PublicText
                        key={nanoid()}
                        value={text}
                        style={{ fontWeight: 'bold', fontSize: '22px' }}
                      />
                    ))}
                    <Gap height={10} />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: i === 2 ? 'flex-start' : 'center',
                      }}
                    >
                      {texts.map(text => (
                        <PublicText
                          key={nanoid()}
                          style={{ textAlign: 'center' }}
                          value={text}
                        />
                      ))}
                    </div>
                  </PublicBox>
                );
              })}
            </S.Infos>
          </S.Contents>
        </S.Container>
      )}
      <ReportSpinner />
      <Modal
        isShown={isShown}
        modalContent={modalContent}
        onClose={onModalClose}
      />
    </>
  );
};
export default ExposedAccount;
