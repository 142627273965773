import { useTranslation } from 'react-i18next';
import ReportSave from '@assets/images/Export_pdf@2x.png';
import ReportSaveDisabled from '@assets/images/Export_pdf_disabled@2x.png';
import GetNew from '@assets/images/Get_new@2x.png';
import GetNewDisabled from '@assets/images/Get_new_disabled@2x.png';
import * as S from './TableOptions.style';

interface Props {
  updateDate?: string | undefined;
  isGetNewHave?: boolean;
  onGetNewClick?: () => void;
  isGetNewDisabled?: boolean;
  isTableOptionsChargedReportSaveDisabled?: boolean;
  onSaveReport?: () => void;
}

export const TableOptions = ({
  updateDate,
  isGetNewHave,
  onGetNewClick,
  isGetNewDisabled,
  isTableOptionsChargedReportSaveDisabled,
  onSaveReport,
}: Props) => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.UpdateDate>
        {updateDate && `${t('translation.DATA_UPDATE_DATE')} : ${updateDate}`}
      </S.UpdateDate>
      <S.Buttons>
        {isGetNewHave && (
          <S.GetNew
            onClick={onGetNewClick}
            isDisabled={isGetNewDisabled as boolean}
          >
            {isGetNewDisabled ? (
              <img
                style={{
                  marginRight: '4px',
                  width: '24px',
                  height: '24px',
                }}
                src={GetNewDisabled}
                alt="get_new"
              />
            ) : (
              <img
                style={{
                  marginRight: '4px',
                  width: '24px',
                  height: '24px',
                }}
                src={GetNew}
                alt="get_new"
              />
            )}
            {t('translation.NEW_DATA_ANALYSIS_BUTTON')}
            {/* 신규 데이터 분석 */}
          </S.GetNew>
        )}
        <S.ExportPDF
          onClick={onSaveReport}
          isDisabled={isTableOptionsChargedReportSaveDisabled as boolean}
        >
          {isTableOptionsChargedReportSaveDisabled ? (
            <img
              style={{
                marginRight: '4px',
                width: '24px',
                height: '24px',
              }}
              src={ReportSaveDisabled}
              alt="export_pdf"
            />
          ) : (
            <img
              style={{
                marginRight: '4px',
                width: '24px',
                height: '24px',
              }}
              src={ReportSave}
              alt="export_pdf"
            />
          )}
          {t('translation.ANALYSIS_DATA_SAVE_BUTTON')}
          {/* 분석 데이터 저장 */}
        </S.ExportPDF>
      </S.Buttons>
    </S.Container>
  );
};
