import { combineReducers, Reducer } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { AuthState, ServiceState } from './models/states';
import { authReducers } from './auth';
import { serviceReducers } from './service';

export interface CombineReducers {
  authReducers: AuthState & PersistPartial;
  serviceReducers: ServiceState & PersistPartial;
}

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['accessToken'],
};
const orderedItemPersistConfig = {
  key: 'orderedItem',
  storage,
  whitelist: ['orderedItem'],
};

const rootReducer: Reducer<CombineReducers> = combineReducers<CombineReducers>({
  authReducers: persistReducer(authPersistConfig, authReducers),
  serviceReducers: persistReducer(orderedItemPersistConfig, serviceReducers),
});
export default rootReducer;
