import { useState, useEffect, useRef } from 'react';

export const useMounted = () => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const initRef = useRef<boolean>(true);

  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
      setIsMounted(true);
    }
  }, []);

  return {
    isMounted,
  };
};
