import styled from 'styled-components';

export const PublicSearchInput = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #cecece;
`;

export const Input = styled.input`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  color: #000;
  outline: 0;
  &::placeholder {
    color: #a1a1a1;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: #a1a1a1;
  }
  &::-ms-input-placeholder {
    color: #a1a1a1;
  }
`;
export const ButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  cursor: pointer;
`;
