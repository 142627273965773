import {
  useCheckIsOrderedMutation,
  useIsDownloadedMutation,
} from '@features/api';

export const useServiceItemCheckApi = () => {
  const [checkIsOrdered] = useCheckIsOrderedMutation();
  const [isDownloaded] = useIsDownloadedMutation();

  const checkKeywordIsOrdered = (service_info: string, keyword: string) => {
    const result = checkIsOrdered({
      service_info,
      keyword,
    })
      .unwrap()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
    return result;
  };
  const checkIsReportDownloaded = (service: any, no: any) => {
    const result = isDownloaded({ service, no })
      .unwrap()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
    return result;
  };
  return { checkKeywordIsOrdered, checkIsReportDownloaded };
};
