import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "KoPubWorldDotum", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    
    /* 드래그 못하도록 설정 */
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  .__react_component_tooltip.show {
    opacity: 1 !important;
    padding: 9px 14px;
  }
`;
