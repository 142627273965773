import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px 50px 0;
  width: 100%;
  max-width: 1560px;
  min-height: 100vh;
`;

export const Gap = styled.div<{ width?: number; height?: number }>`
  width: ${({ width }) => (width ? `${width}px;` : '100%')};
  height: ${({ height }) => (height ? `${height}px;` : '100%')};
`;
