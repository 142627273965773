import { t } from 'i18next';

export const onConvertPopularPostingExposureSuitability = (data: {
  postLevel: string;
  followerLevel: string;
  activityLevel: string;
  rankLevel: string;
  rankWord: string;
  workType: string;
}) => {
  const res = { ...data };
  const INSUFFICIENT = t('translation.INSUFFICIENT'); // 미달
  const AMBIGUOUS = t('translation.AMBIGUOUS'); // 모호
  const SATISFIED = t('translation.SATISFIED'); // 충족

  // 활동량
  if (data.activityLevel === '미달') {
    res.activityLevel = INSUFFICIENT;
  }
  if (data.activityLevel === '모호') {
    res.activityLevel = AMBIGUOUS;
  }
  if (data.activityLevel === '충족') {
    res.activityLevel = SATISFIED;
  }

  // 팔로워수
  if (data.followerLevel === '미달') {
    res.followerLevel = INSUFFICIENT;
  }
  if (data.followerLevel === '모호') {
    res.followerLevel = AMBIGUOUS;
  }
  if (data.followerLevel === '충족') {
    res.followerLevel = SATISFIED;
  }

  // 포스트수
  if (data.postLevel === '미달') {
    res.postLevel = INSUFFICIENT;
  }
  if (data.postLevel === '모호') {
    res.postLevel = AMBIGUOUS;
  }
  if (data.postLevel === '충족') {
    res.postLevel = SATISFIED;
  }

  // rank word
  if (data.rankWord === '아주미달') {
    res.rankWord = t(
      'translation.POPULAR_POSTING_EXPOSURE_SUITABILITY_STATUS1'
    );
  }
  if (data.rankWord === '미달') {
    res.rankWord = t(
      'translation.POPULAR_POSTING_EXPOSURE_SUITABILITY_STATUS2'
    );
  }
  if (data.rankWord === '조금미달') {
    res.rankWord = t(
      'translation.POPULAR_POSTING_EXPOSURE_SUITABILITY_STATUS3'
    );
  }
  if (data.rankWord === '보통') {
    res.rankWord = t(
      'translation.POPULAR_POSTING_EXPOSURE_SUITABILITY_STATUS4'
    );
  }
  if (data.rankWord === '적합') {
    res.rankWord = t(
      'translation.POPULAR_POSTING_EXPOSURE_SUITABILITY_STATUS5'
    );
  }
  if (data.rankWord === '아주적합') {
    res.rankWord = t(
      'translation.POPULAR_POSTING_EXPOSURE_SUITABILITY_STATUS6'
    );
  }

  // workType
  if (data.workType === '자동 좋아요, 댓글, 트래픽 작업 추천') {
    res.workType = t(
      'translation.POPULAR_POSTING_EXPOSURE_SUITABILITY_STATUS3_TEXT2'
    );
  }

  if (
    data.workType ===
    '자동 육성(선팔, 선좋아요를 통한 실유저 팔로워 늘리기 및 활동량 증가) 작업 추천'
  ) {
    res.workType = t(
      'translation.POPULAR_POSTING_EXPOSURE_SUITABILITY_STATUS1_TEXT1'
    );
  }

  if (data.workType === '자동 좋아요, 댓글, 트래픽 작업 + 팔로워 작업 추천') {
    res.workType = t(
      'translation.POPULAR_POSTING_EXPOSURE_SUITABILITY_STATUS3_TEXT3'
    );
  }

  if (
    data.workType ===
    '노출에 부적합한 게시물중 모자른 수치 만큼 자동 혹은 수동 좋아요, 댓글, 트래픽 작업 작업을 추천합니다'
  ) {
    res.workType = t(
      'translation.POPULAR_POSTING_EXPOSURE_SUITABILITY_STATUS6_TEXT1'
    );
  }

  if (
    data.workType ===
    '최근 반 이상의 게시물이 노출에 적합합니다. 노출에 부적합한 게시물중 모자른 수치 만큼 자동 혹은 수동 좋아요, 댓글, 트래픽 작업 작업을 추천합니다'
  ) {
    res.workType = t(
      'translation.POPULAR_POSTING_EXPOSURE_SUITABILITY_STATUS6_TEXT2'
    );
  }

  if (
    data.workType ===
    '대부분의 게시물이 노출에 적합합니다. 작업을 넣는다면 계정 컨셉을 파악한후 섬세하고 디테일한 자동 혹은 수동 좋아요, 댓글, 트래픽 작업 작업을 추천합니다'
  ) {
    res.workType = t(
      'translation.POPULAR_POSTING_EXPOSURE_SUITABILITY_STATUS6_TEXT3'
    );
  }

  return res;
};
