import styled from 'styled-components';

export * from './Carousel';
export * from './Modal';
export * from './Spinner';
export * from './ReportSpinner';

export { default as ReportSpinner } from './ReportSpinner';

export const Gap = styled.div<{ width?: number; height?: number }>`
  width: ${({ width }) => (width ? `${width}px;` : '100%')};
  height: ${({ height }) => (height ? `${height}px;` : '100%')};
`;

export const FlexContainer = styled.div<{ width?: number }>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;
