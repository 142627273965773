import { useState } from 'react';
import { t } from 'i18next';
/* eslint-disable new-cap */
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';
import download from 'downloadjs';
import { REPORT_ASSETS } from '@constants';
import { useCreateReportMutation } from '@features/api';
import { convertNumberNotation, addCommaToNumber } from '@utils';
import {
  useTypedSelector,
  useTypedDispatch,
  RootState,
} from '@features/rootStore';
import { changeIsReportDownloadState } from '@features/service';

/**
 * 서비스명
 */
const serviceName = {
  STARTAGFREE: 'Hashtag Analytics Report',
  STARTAGCHARGED: 'Hashtag Analytics Report',
  EXPOSED: 'Popular posts Analytics Report',
  ACCOUNT: 'Account Analytics Report ',
  POST: 'Post Increase Analytics Report',
  TOP: 'Account Analytics Report ',
  HOT: 'Hashtag Analytics Report',
};

/**
 * jsPDF doc 기본 세팅
 */
function setDefault(doc: jsPDF): jsPDF {
  doc.addFileToVFS('kopubM', REPORT_ASSETS.kopubM);
  doc.addFileToVFS('kopubL', REPORT_ASSETS.kopubL);
  doc.addFileToVFS('kopubB', REPORT_ASSETS.kopubB);
  doc.addFont('kopubM', 'kopub', 'normal');
  doc.addFont('kopubL', 'kopub', 'light');
  doc.addFont('kopubB', 'kopub', 'bold');
  doc.setFont('kopub');
  return doc;
}

/**
 * 앞표지
 */
function setFrontCover(
  doc: jsPDF,
  service: string,
  date: string,
  keyword?: string | null,
  companyName?: any,
  recipient?: string
): jsPDF {
  const trimmedRecipientName = recipient?.trim();
  if (companyName === '그로스비즈') {
    doc.setFillColor(53, 41, 211);
  }
  if (companyName !== '그로스비즈') {
    doc.setFillColor(74, 91, 253);
  }

  doc.rect(0, 0, 210, 297, 'F');
  doc.addImage(REPORT_ASSETS.frontcovermask, 'PNG', 0, 0, 210, 297);
  doc.addImage(REPORT_ASSETS.frontcoverIcon, 'PNG', 90, 100, 30, 30);
  doc.setTextColor('#FFFFFF');
  if (trimmedRecipientName) {
    doc.setFontSize((150 * 72) / 300);
    doc
      .setFont('kopub', 'bold')
      .text(trimmedRecipientName, 105, 146, { align: 'center' });
  }
  doc.setFontSize((80 * 72) / 300);
  doc
    .setFont('kopub', 'bold')
    .text(service, 105, trimmedRecipientName ? 160 : 146, { align: 'center' });

  doc.setFont('kopub', 'normal');
  doc.setFontSize((60 * 72) / 300);
  doc.text(`${t('translation.ANALYSIS_DATE')} : ${date}`, 10, 260);
  if (keyword) {
    doc.text(`${t('translation.ANALYSIS_KEYWORD')} : ${keyword}`, 10, 269);
  }
  doc.setFontSize((80 * 72) / 300);
  doc.setFont('kopub', 'bold');
  doc.text(companyName, 10, 280);
  doc.addPage();
  return doc;
}

/**
 * 뒷표지
 */
function setBackCover(doc: jsPDF, companyName?: any): jsPDF {
  doc.addPage();
  if (companyName === '그로스비즈') {
    doc.setFillColor(53, 41, 211);
  }
  if (companyName !== '그로스비즈') {
    doc.setFillColor(74, 91, 253);
  }
  doc.rect(0, 0, 210, 297, 'F');
  doc.setTextColor('#FFFFFF');
  doc.setFontSize((120 * 72) / 300);
  doc.setFont('kopub', 'bold').text('Thank You', 105, 160, { align: 'center' });
  doc.setDrawColor(232, 232, 232);
  doc.line(8.46, 264, 201.5, 264);
  doc
    .setFont('kopub', 'normal')
    .setFontSize((50 * 72) / 300)
    .text(
      // `해당 분석 데이터의 저작권은 '${companyName}'에 있습니다.`,
      t('translation.PDF_NOTICE_TEXT1', { companyName }),
      8.46,
      275
    );
  doc.text(
    // '무단으로 캡쳐, 도용, 컨텐츠를 복사하여 사용하는 것은 지적재산권 위반에 해당되며',
    t('translation.PDF_NOTICE_TEXT2'),
    8.46,
    281
  );
  doc.text(
    // '경고없이 민, 형사상법적조치 등 저작권법에 의한 처벌을 받을 수 있습니다.',
    t('translation.PDF_NOTICE_TEXT3'),
    8.46,
    287
  );
  return doc;
}

/**
 * 게시물증가추이 내부
 */
async function setPostIncreaseContent(
  doc: jsPDF,
  keyword: string,
  date: string,
  charts: any[],
  postCnt: string,
  increaseCnt: string
) {
  let pageNum = 1;
  function setHeaderAndFooter(num: number) {
    doc
      .setFont('kopub', 'normal')
      .setTextColor(0, 0, 0)
      .setFontSize((35 * 72) / 300)
      .text(`${t('translation.ANALYSIS_KEYWORD')} : ${keyword}`, 10, 10, {
        align: 'left',
      });
    doc
      .setTextColor(0, 0, 0)
      .setFontSize((35 * 72) / 300)
      .text(`${t('translation.ANALYSIS_STANDARD_DATE')} : ${date}`, 200, 10, {
        align: 'right',
      });
    doc.setDrawColor(232, 232, 232);
    doc.line(8.46, 14, 201.5, 14);
    doc.setFillColor(249, 249, 249);
    doc.rect(0, 287, 210, 10, 'F');
    doc
      .setTextColor(0, 0, 0)
      .setFontSize((35 * 72) / 300)
      .text(num.toString(), 104.98, 293, { align: 'center' })
      .text(t('translation.POSTING_INCREASE_TRANSITION'), 200, 293, {
        align: 'right',
      });
    pageNum += 1;
  }
  setHeaderAndFooter(pageNum);

  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(t('translation.POSTING_INCREASE_STATUS'), 8.46, 28);
  doc
    .setFont('kopub', 'normal')
    .setTextColor(85, 85, 85)
    .setFontSize((50 * 72) / 300)
    .text(
      // '해시태그의 게시물 증가량을 분석하여 수요 정도를 제공합니다.',
      t('translation.POSTING_INCREASE_STATUS_TEXT'),
      8.46,
      35
    );

  doc.setDrawColor(232, 232, 232);
  doc.roundedRect(8, 43, 93, 52, 1.5, 1.5, 'S');
  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((50 * 72) / 300)
    .text(t('translation.POSTING_COUNT'), 46, 53);
  doc.addImage(REPORT_ASSETS.postIncrease.postCnt, 45, 60, 19.5, 19.5);
  doc
    .setFontSize((70 * 72) / 300)
    .text(addCommaToNumber(postCnt), 55, 90, { align: 'center' });

  doc.roundedRect(107, 43, 93, 52, 1.5, 1.5, 'S');
  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((50 * 72) / 300)
    .text(t('translation.MONTHLY_AVERAGE_POSTING_INCREASE_AMOUNT'), 130, 53);
  doc.addImage(REPORT_ASSETS.postIncrease.increaseCnt, 145, 60, 19.5, 19.5);
  doc
    .setFontSize((70 * 72) / 300)
    .text(addCommaToNumber(increaseCnt), 155, 90, { align: 'center' });

  doc.roundedRect(8, 102, 69, 67, 1.5, 1.5, 'S');
  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((50 * 72) / 300)
    .text(t('translation.DAYS_POSTING_INCREASE_COUNT'), 25, 110);
  await html2canvas(charts[0], { scale: 2 }).then(canvas => {
    doc.addImage(canvas.toDataURL(), 15, 115, 55, 52);
  });

  doc.roundedRect(83, 102, 117, 67, 1.5, 1.5, 'S');
  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((50 * 72) / 300)
    .text(t('translation.MONTHLY_POSTING_INCREASE_COUNT'), 124, 110);
  await html2canvas(charts[1], { scale: 2 }).then(canvas => {
    doc.addImage(canvas.toDataURL(), 91, 115, 102, 52);
  });

  doc
    .setFontSize((70 * 72) / 300)
    .text(t('translation.POST_INCREASE_TRANSITION'), 8.46, 188);
  doc
    .setFont('kopub', 'normal')
    .setTextColor(85, 85, 85)
    .setFontSize((50 * 72) / 300)
    .text(t('translation.PREVIOUS_YEAR_POSTING_COUNT_CHANGE_GRAPH'), 8.46, 195);

  doc.roundedRect(8, 200, 193, 72, 1.5, 1.5, 'S');
  await html2canvas(charts[2], { scale: 2 }).then(canvas => {
    doc.addImage(canvas.toDataURL(), 15, 205, 185, 60);
  });

  return doc;
}
/**
 * 계정분석 내부
 */
async function setAccountContent(
  doc: jsPDF,
  keyword: string,
  date: string,
  basicInfo: any,
  accountSuitability: any,
  hashtags: string[],
  images: any,
  chartDates: any,
  service?: any,
  profileImg?: any
) {
  function fullNameLineBreak(fullName: string) {
    if (fullName.length > 18) {
      const firstLine = fullName.slice(0, 18);
      const secondLine = fullName.slice(18);
      return `${firstLine}\n${secondLine}`;
    }
    return fullName;
  }
  function limitBioHeight(bio: string) {
    const textsByLine = bio.split('\n');

    const textsByTrimmedLine = textsByLine.reduce((acc: any, cur) => {
      if (cur.length > 80) {
        const firstLine = cur.slice(0, 40);
        const secondLine = cur.slice(40, 80);
        const thirdLine = cur.slice(80);
        acc.push(firstLine);
        acc.push(secondLine);
        acc.push(thirdLine);
      } else if (cur.length > 40) {
        const firstLine = cur.slice(0, 40);
        const secondLine = cur.slice(40);
        acc.push(firstLine);
        acc.push(secondLine);
      } else {
        acc.push(cur);
      }
      return acc;
    }, []);
    const neededTextsByLine = textsByTrimmedLine.slice(0, 6);
    return neededTextsByLine.join('\n');
  }
  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(t('translation.ACCOUNT_INFORMATION'), 8.46, 28);
  doc
    .setFont('kopub', 'normal')
    .setTextColor(85, 85, 85)
    .setFontSize((50 * 72) / 300)
    .text(t('translation.ACCOUNT_INFORMATION_TEXT'), 8.46, 35);

  doc.setDrawColor(232, 232, 232);
  doc.roundedRect(8, 43, 193, 67, 1.5, 1.5, 'S');
  doc.addImage(profileImg, 20, 55, 42, 42);

  doc.setLineWidth(10);
  doc.setDrawColor(255, 255, 255);
  doc.circle(41, 76, 25, 'S');

  doc.setDrawColor(232, 232, 232);
  doc.setLineWidth(0.2);
  doc
    .setFont('kopub', 'bold')
    .setFontSize((80 * 72) / 300)
    .setTextColor('#4A5BFD')
    .text(convertNumberNotation(basicInfo.post), 140, 63, { align: 'center' });
  doc
    .setFont('kopub', 'bold')
    .setFontSize((45 * 72) / 300)
    .setTextColor('#555555')
    .text(t('translation.TABLE_POST'), 140, 70, { align: 'center' });

  doc
    .setFont('kopub', 'bold')

    .setFontSize((80 * 72) / 300)
    .setTextColor('#2D7AFF')
    .text(convertNumberNotation(basicInfo.follower), 160, 63, {
      align: 'center',
    });
  doc
    .setFont('kopub', 'bold')
    .setFontSize((45 * 72) / 300)
    .setTextColor('#555555')
    .text(t('translation.TABLE_FOLLOWER'), 160, 70, { align: 'center' });

  doc
    .setFont('kopub', 'bold')
    .setFontSize((80 * 72) / 300)
    .setTextColor('#9068FF')
    .text(convertNumberNotation(basicInfo.follow), 180, 63, {
      align: 'center',
    });
  doc
    .setFont('kopub', 'bold')
    .setFontSize((45 * 72) / 300)
    .setTextColor('#555555')
    .text(t('translation.TABLE_FOLLOW'), 180, 70, { align: 'center' });

  doc
    .setFont('kopub', 'bold')

    .setFontSize((70 * 72) / 300)
    .setTextColor('#000000')
    .text(basicInfo.userName.trim(), 70, 60, {
      maxWidth: (580 * 72) / 300,
      align: 'left',
    });
  doc
    .setFont('kopub', 'normal')
    .setFontSize((50 * 72) / 300)
    .setTextColor('#000000')
    .text(fullNameLineBreak(basicInfo.fullName.trim()), 70, 72);
  doc
    .setFontSize((40 * 72) / 300)
    .setTextColor('#555555')
    .text(limitBioHeight(basicInfo.biography.trim()), 70, 84, {
      maxWidth: (1000 * 72) / 300,
      align: 'left',
    });

  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(
      `${t('translation.ACCOUNT_ACTIVITY')} / ${t(
        'translation.ACQUIRED_RESPONSE_AMOUNT_TREND'
      )}`,
      8.46,
      128
    );
  doc
    .setFont('kopub', 'normal')
    .setTextColor(85, 85, 85)
    .setFontSize((50 * 72) / 300)
    .text(
      // '기간 내 계정 활동에 따른 반응도 변화를 분석한 그래프입니다.',
      t('translation.ACQUIRED_RESPONSE_AMOUNT_TREND_TEXT'),
      8.46,
      135
    );

  doc.roundedRect(8, 143, 93, 57, 1.5, 1.5, 'S');
  doc
    .setTextColor(0, 0, 0)
    .setFontSize((50 * 72) / 300)
    .setFont('kopub', 'bold')
    .text(t('translation.FOLLOWING_COUNT'), 13, 152);
  if (images.charts.length === 0) {
    doc
      .setFont('kopub', 'bold')
      .setTextColor(0, 0, 0)
      .setFontSize((40 * 72) / 300)
      .text(t('translation.TABLE_NO_LIST_TITLE'), 55, 173, { align: 'center' });
    doc
      .setFont('kopub', 'normal')
      .setTextColor(0, 0, 0)
      .text(t('translation.TABLE_NO_LIST_TEXT1'), 55, 179, {
        align: 'center',
      });
    doc
      .setFont('kopub', 'normal')
      .setTextColor(0, 0, 0)
      .text(t('translation.TABLE_NO_LIST_TEXT2'), 55, 183, { align: 'center' });
  } else {
    await html2canvas(images.charts[0], { scale: 2 }).then(canvas => {
      doc.addImage(canvas.toDataURL(), 11, 155, 85, 31);
    });
    doc.setTextColor(161, 161, 161).text(chartDates.follow, 40, 193);
  }

  doc.roundedRect(107, 143, 93, 57, 1.5, 1.5, 'S');
  doc
    .setTextColor(0, 0, 0)
    .setFontSize((50 * 72) / 300)
    .setFont('kopub', 'bold')
    .text(t('translation.POST_COUNT'), 113, 152);
  if (images.charts.length === 0) {
    doc
      .setFont('kopub', 'bold')
      .setTextColor(0, 0, 0)
      .setFontSize((40 * 72) / 300)
      .text(t('translation.TABLE_NO_LIST_TITLE'), 155, 173, {
        align: 'center',
      });
    doc
      .setFont('kopub', 'normal')
      .setTextColor(0, 0, 0)
      .text(t('translation.TABLE_NO_LIST_TEXT1'), 155, 179, {
        align: 'center',
      });
    doc
      .setFont('kopub', 'normal')
      .setTextColor(0, 0, 0)
      .text(t('translation.TABLE_NO_LIST_TEXT2'), 155, 183, {
        align: 'center',
      });
  } else {
    await html2canvas(images.charts[1], { scale: 2 }).then(canvas => {
      doc.addImage(canvas.toDataURL(), 111, 155, 85, 31);
    });
    doc.setTextColor(161, 161, 161).text(chartDates.post, 140, 193);
  }
  doc.roundedRect(8, 205, 93, 57, 1.5, 1.5, 'S');
  doc
    .setTextColor(0, 0, 0)
    .setFontSize((50 * 72) / 300)
    .setFont('kopub', 'bold')
    .text(t('translation.FOLLOWER_COUNT'), 13, 214);
  if (images.charts.length === 0) {
    doc
      .setFont('kopub', 'bold')
      .setTextColor(0, 0, 0)
      .setFontSize((40 * 72) / 300)
      .text(t('translation.TABLE_NO_LIST_TITLE'), 55, 235, { align: 'center' });
    doc
      .setFont('kopub', 'normal')
      .setTextColor(0, 0, 0)
      .text(t('translation.TABLE_NO_LIST_TEXT1'), 55, 241, {
        align: 'center',
      });
    doc
      .setFont('kopub', 'normal')
      .setTextColor(0, 0, 0)
      .text(t('translation.TABLE_NO_LIST_TEXT2'), 55, 245, { align: 'center' });
  } else {
    await html2canvas(images.charts[2], { scale: 2 }).then(canvas => {
      doc.addImage(canvas.toDataURL(), 11, 217, 85, 31);
    });
    doc.setTextColor(161, 161, 161).text(chartDates.follower, 40, 255);
  }
  doc.roundedRect(107, 205, 93, 57, 1.5, 1.5, 'S');
  doc
    .setTextColor(0, 0, 0)
    .setFontSize((50 * 72) / 300)
    .setFont('kopub', 'bold')
    .text(t('translation.LIKE_COUNT_AVERAGE'), 113, 214);
  if (images.charts.length === 0) {
    doc
      .setFont('kopub', 'bold')
      .setTextColor(0, 0, 0)
      .setFontSize((40 * 72) / 300)
      .text(t('translation.TABLE_NO_LIST_TITLE'), 155, 235, {
        align: 'center',
      });
    doc
      .setFont('kopub', 'normal')
      .setTextColor(0, 0, 0)
      .text(t('translation.TABLE_NO_LIST_TEXT1'), 155, 241, {
        align: 'center',
      });
    doc
      .setFont('kopub', 'normal')
      .setTextColor(0, 0, 0)
      .text(t('translation.TABLE_NO_LIST_TEXT2'), 155, 245, {
        align: 'center',
      });
  } else {
    await html2canvas(images.charts[3], { scale: 2 }).then(canvas => {
      doc.addImage(canvas.toDataURL(), 111, 217, 85, 31);
    });
    doc.setTextColor(161, 161, 161).text(chartDates.likeAverage, 140, 255);
  }
  doc.addPage();

  const isMoreThanFifty = hashtags.length > 50;

  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(t('translation.POPULAR_POSTING_EXPOSURE_HASHTAG'), 8.46, 28);
  doc
    .setFont('kopub', 'normal')
    .setTextColor(85, 85, 85)
    .setFontSize((50 * 72) / 300)
    .text(
      // '계정 활동을 통해 인기게시물 순위에 오른 해시태그 목록입니다. (최대 100개)',
      t('translation.POPULAR_POSTING_EXPOSURE_HASHTAG_TEXT'),
      8.46,
      35
    );
  doc.roundedRect(8, 43, 193, isMoreThanFifty ? 130 : 65, 1.5, 1.5, 'S');
  if (hashtags.length === 0) {
    doc
      .setFont('kopub', 'bold')
      .setTextColor(0, 0, 0)
      .setFontSize((50 * 72) / 300)
      .text(t('translation.TABLE_NO_LIST_TITLE'), 105, 73, { align: 'center' });
    doc
      .setFont('kopub', 'normal')
      .setTextColor(0, 0, 0)
      .setFontSize((40 * 72) / 300)
      .text(t('translation.TABLE_NO_LIST_TEXT1'), 105, 80, {
        align: 'center',
      });
    doc
      .setFont('kopub', 'normal')
      .setTextColor(0, 0, 0)
      .setFontSize((40 * 72) / 300)
      .text(t('translation.TABLE_NO_LIST_TEXT2'), 105, 84, { align: 'center' });
  } else {
    const xCoordinateInit = 14;
    const yCoordinateInit = 50;
    const yCoordinateSecond = 115;
    let xCoordinate;
    let yCoordinate;
    const xMove = 37;
    const yMove = 6;
    hashtags
      .filter((_, i: number) => {
        return i < 100;
      })
      .forEach((hashtag: string, i: number) => {
        xCoordinate = xCoordinateInit;
        yCoordinate = yCoordinateInit + i * yMove;
        if (i > 9) {
          xCoordinate += xMove;
          yCoordinate = yCoordinateInit + (i - 10) * yMove;
        }
        if (i > 19) {
          xCoordinate += xMove;
          yCoordinate = yCoordinateInit + (i - 20) * yMove;
        }
        if (i > 29) {
          xCoordinate += xMove;
          yCoordinate = yCoordinateInit + (i - 30) * yMove;
        }
        if (i > 39) {
          xCoordinate += xMove;
          yCoordinate = yCoordinateInit + (i - 40) * yMove;
        }
        if (i > 49) {
          xCoordinate = xCoordinateInit;
          yCoordinate = yCoordinateSecond + (i - 50) * yMove;
        }
        if (i > 59) {
          xCoordinate = xCoordinateInit + xMove;
          yCoordinate = yCoordinateSecond + (i - 60) * yMove;
        }
        if (i > 69) {
          xCoordinate += xMove;
          yCoordinate = yCoordinateSecond + (i - 70) * yMove;
        }
        if (i > 79) {
          xCoordinate += xMove;
          yCoordinate = yCoordinateSecond + (i - 80) * yMove;
        }
        if (i > 89) {
          xCoordinate += xMove;
          yCoordinate = yCoordinateSecond + (i - 90) * yMove;
        }
        doc
          .setTextColor(0, 0, 0)
          .setFontSize((40 * 72) / 300)
          .text(`${i + 1}. ${hashtag}`, xCoordinate, yCoordinate);
      });
  }

  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(
      t('translation.POPULAR_POSTING_EXPOSURE_SUITABILITY'),
      8.46,
      isMoreThanFifty ? 191 : 126
    );
  doc
    .setFont('kopub', 'normal')
    .setTextColor(85, 85, 85)
    .setFontSize((50 * 72) / 300)
    .text(
      // '계정의 최근 활동이 인기게시물 노출에 적합한지를 판단한 분석 결과입니다.',
      t('translation.POPULAR_POSTING_EXPOSURE_SUITABILITY_TEXT'),
      8.46,
      isMoreThanFifty ? 198 : 133
    );
  doc.roundedRect(8, isMoreThanFifty ? 206 : 141, 193, 74, 1.5, 1.5, 'S');
  doc.addImage(
    REPORT_ASSETS.accounEvaulationBulbIcon,
    75,
    isMoreThanFifty ? 213 : 148,
    10,
    10
  );
  doc
    .setFont('kopub', 'bold')
    .setFontSize((90 * 72) / 300)
    .setTextColor('#4A5BFD')
    .text(accountSuitability.rankWord, 103, isMoreThanFifty ? 220 : 155, {
      align: 'center',
    });
  function lineBreak(workType: string) {
    if (workType.length > 50) {
      const firstLine = workType.slice(0, 50);
      const secondLine = workType.slice(50);
      return `${firstLine}\n${secondLine}`;
    }
    return workType;
  }
  doc
    .setFont('kopub', 'normal')
    .setFontSize((40 * 72) / 300)
    .setTextColor('#000000')
    .text(
      lineBreak(accountSuitability.workType),
      105,
      isMoreThanFifty ? 230 : 162,
      {
        align: 'center',
      }
    );
  await html2canvas(images.detailSuitability, { scale: 2 }).then(canvas => {
    doc.addImage(canvas.toDataURL(), 13, isMoreThanFifty ? 245 : 177, 184, 28);
  });

  const pageCount = doc.getNumberOfPages();
  for (let i = 0; i < pageCount; i += 1) {
    doc.setPage(i);
    const pageNum = doc.getCurrentPageInfo().pageNumber;
    if (pageNum !== 1) {
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(`${t('translation.ANALYSIS_KEYWORD')} : ${keyword}`, 10, 10, {
          align: 'left',
        });
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(`${t('translation.ANALYSIS_STANDARD_DATE')} : ${date}`, 200, 10, {
          align: 'right',
        });
      doc.setDrawColor(232, 232, 232);
      doc.line(8.46, 14, 201.5, 14);

      doc.setFillColor(249, 249, 249);
      doc.rect(0, 287, 210, 10, 'F');
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(
          (doc.getCurrentPageInfo().pageNumber - 1).toString(),
          104.98,
          293,
          { align: 'center' }
        )
        .text(t('translation.ACCOUNT_ANALYSIS'), 200, 293, { align: 'right' });
    }
  }
  return doc;
}
/**
 * 스타태그 무료상품 내부
 */
async function setStartagFreeContent(
  doc: jsPDF,
  keyword: string,
  date: string,
  tags: any
) {
  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(
      `${t('translation.STAR_TAG')} - ${t(
        'translation.RECOMMEND_RELATION_HASHTAG'
      )}`,
      8.46,
      28
    );
  doc
    .setFont('kopub', 'normal')
    .setTextColor(85, 85, 85)
    .setFontSize((50 * 72) / 300)
    .text(
      // '검색 키워드와 연관성이 높은 해시태그로, 함께 사용하면 마케팅 효과가 더욱 뛰어납니다.',
      t('translation.RECOMMEND_RELATION_HASHTAG_TEXT'),
      8.46,
      35
    );
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 23 },
      1: { cellWidth: 60 },
      2: { cellWidth: 60 },
      3: { cellWidth: 50 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        'NO',
        t('translation.TABLE_COLLECTION_STANDARD'),
        t('translation.TABLE_COLLECTION_HASHTAG'),
        t('translation.TABLE_POSTING_COUNT'),
      ],
    ],
    body: [...tags],
  });
  const pageCount = doc.getNumberOfPages();
  for (let i = 0; i < pageCount; i += 1) {
    doc.setPage(i);
    const pageNum = doc.getCurrentPageInfo().pageNumber;
    if (pageNum !== 1) {
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(`${t('translation.ANALYSIS_KEYWORD')} : ${keyword}`, 10, 10, {
          align: 'left',
        });
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(`${t('translation.ANALYSIS_STANDARD_DATE')} : ${date}`, 200, 10, {
          align: 'right',
        });
      doc.setDrawColor(232, 232, 232);
      doc.line(8.46, 14, 201.5, 14);

      doc.setFillColor(249, 249, 249);
      doc.rect(0, 287, 210, 10, 'F');
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(
          (doc.getCurrentPageInfo().pageNumber - 1).toString(),
          104.98,
          293,
          { align: 'center' }
        )
        .text(
          `${t('translation.STAR_TAG')} – ${t(
            'translation.RECOMMEND_RELATION_HASHTAG'
          )}`,
          200,
          293,
          { align: 'right' }
        );
    }
  }
  return doc;
}
/**
 * 스타태그 유료상품 내부
 */
async function setStartagChargedContent(
  doc: jsPDF,
  keyword: string,
  date: string,
  tags: any
) {
  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(
      `${t('translation.STAR_TAG')} - ${t(
        'translation.TABLE_COLLECTION_STANDARD_TYPE1'
      )}`,
      8.46,
      28
    );
  doc
    .setFont('kopub', 'normal')
    .setTextColor(85, 85, 85)
    .setFontSize((50 * 72) / 300)
    .text(
      // '검색 키워드와 연관성이 높은 해시태그로, 함께 사용하면 마케팅 효과가 더욱 뛰어납니다.',
      t('translation.RECOMMEND_RELATION_HASHTAG_TEXT'),
      8.46,
      35
    );
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 23 },
      1: { cellWidth: 60 },
      2: { cellWidth: 60 },
      3: { cellWidth: 50 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        'NO',
        t('translation.TABLE_COLLECTION_STANDARD'),
        t('translation.TABLE_COLLECTION_HASHTAG'),
        t('translation.TABLE_POSTING_COUNT'),
      ],
    ],
    body: [...tags],
  });
  const pageCount = doc.getNumberOfPages();
  for (let i = 0; i < pageCount; i += 1) {
    doc.setPage(i);
    const pageNum = doc.getCurrentPageInfo().pageNumber;
    if (pageNum !== 1) {
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(`${t('translation.ANALYSIS_KEYWORD')} : ${keyword}`, 10, 10, {
          align: 'left',
        });
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(`${t('translation.ANALYSIS_STANDARD_DATE')} : ${date}`, 200, 10, {
          align: 'right',
        });
      doc.setDrawColor(232, 232, 232);
      doc.line(8.46, 14, 201.5, 14);

      doc.setFillColor(249, 249, 249);
      doc.rect(0, 287, 210, 10, 'F');

      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(
          (doc.getCurrentPageInfo().pageNumber - 1).toString(),
          104.98,
          293,
          { align: 'center' }
        )
        .text(
          `${t('translation.STAR_TAG')} - ${t(
            'translation.TABLE_COLLECTION_STANDARD_TYPE1'
          )}`,
          200,
          293,
          { align: 'right' }
        );
    }
  }
  return doc;
}
/**
 * 인기게시물노출계정분석 내부
 */
async function setExposedContent(
  doc: jsPDF,
  keyword: string,
  date: string,
  top7: any,
  top30: any,
  top90: any,
  top180: any,
  topLike: any,
  topComment: any
) {
  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(
      t('translation.RECENT_WEEK_POPULAR_POSTING_ACCOUNT_RANKING'),
      8.46,
      28
    );
  doc
    .setFont('kopub', 'normal')
    .setFontSize((50 * 72) / 300)
    .setTextColor(85, 85, 85)
    .text(
      // '분석한 해시태그 내에서 최근 N일동안 가장 인기가 많았던 계정 순위입니다.',
      t('translation.RECENT_WEEK_POPULAR_POSTING_ACCOUNT_RANKING_TEXT'),
      8.46,
      35
    );
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 15 },
      1: { cellWidth: 23 },
      2: { cellWidth: 53 },
      3: { cellWidth: 25 },
      4: { cellWidth: 25 },
      5: { cellWidth: 25 },
      6: { cellWidth: 25 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        'NO',
        t('translation.TABLE_SCORE'),
        t('translation.TABLE_ACCOUNT'),
        t('translation.TABLE_FOLLOWER'),
        t('translation.TABLE_FOLLOW'),
        t('translation.TABLE_POST'),
        t('translation.TABLE_AVERAGE_LIKE'),
      ],
    ],
    body: [...top7],
  });
  doc.addPage();

  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(
      t('translation.RECENT_MONTH_POPULAR_POSTING_ACCOUNT_RANKING'),
      8.46,
      28
    );
  doc
    .setFont('kopub', 'normal')
    .setFontSize((50 * 72) / 300)
    .setTextColor(85, 85, 85)
    .text(
      // '분석한 해시태그 내에서 최근 N일동안 가장 인기가 많았던 계정 순위입니다.',
      t('translation.RECENT_WEEK_POPULAR_POSTING_ACCOUNT_RANKING_TEXT'),
      8.46,
      35
    );
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 15 },
      1: { cellWidth: 23 },
      2: { cellWidth: 53 },
      3: { cellWidth: 25 },
      4: { cellWidth: 25 },
      5: { cellWidth: 25 },
      6: { cellWidth: 25 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        'NO',
        t('translation.TABLE_SCORE'),
        t('translation.TABLE_ACCOUNT'),
        t('translation.TABLE_FOLLOWER'),
        t('translation.TABLE_FOLLOW'),
        t('translation.TABLE_POST'),
        t('translation.TABLE_AVERAGE_LIKE'),
      ],
    ],
    body: [...top30],
  });
  doc.addPage();

  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(
      t('translation.RECENT_QUARTER_POPULAR_POSTING_ACCOUNT_RANKING'),
      8.46,
      28
    );
  doc
    .setFont('kopub', 'normal')
    .setFontSize((50 * 72) / 300)
    .setTextColor(85, 85, 85)
    .text(
      // '분석한 해시태그 내에서 최근 N일동안 가장 인기가 많았던 계정 순위입니다.',
      t('translation.RECENT_WEEK_POPULAR_POSTING_ACCOUNT_RANKING_TEXT'),
      8.46,
      35
    );
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 15 },
      1: { cellWidth: 23 },
      2: { cellWidth: 53 },
      3: { cellWidth: 25 },
      4: { cellWidth: 25 },
      5: { cellWidth: 25 },
      6: { cellWidth: 25 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        'NO',
        t('translation.TABLE_SCORE'),
        t('translation.TABLE_ACCOUNT'),
        t('translation.TABLE_FOLLOWER'),
        t('translation.TABLE_FOLLOW'),
        t('translation.TABLE_POST'),
        t('translation.TABLE_AVERAGE_LIKE'),
      ],
    ],
    body: [...top90],
  });
  doc.addPage();

  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(
      t('translation.RECENT_HALF_YEAR_POPULAR_POSTING_ACCOUNT_RANKING'),
      8.46,
      28
    );
  doc
    .setFont('kopub', 'normal')
    .setFontSize((50 * 72) / 300)
    .setTextColor(85, 85, 85)
    .text(
      // '분석한 해시태그 내에서 최근 N일동안 가장 인기가 많았던 계정 순위입니다.',
      t('translation.RECENT_WEEK_POPULAR_POSTING_ACCOUNT_RANKING_TEXT'),
      8.46,
      35
    );
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 15 },
      1: { cellWidth: 23 },
      2: { cellWidth: 53 },
      3: { cellWidth: 25 },
      4: { cellWidth: 25 },
      5: { cellWidth: 25 },
      6: { cellWidth: 25 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        'NO',
        t('translation.TABLE_SCORE'),
        t('translation.TABLE_ACCOUNT'),
        t('translation.TABLE_FOLLOWER'),
        t('translation.TABLE_FOLLOW'),
        t('translation.TABLE_POST'),
        t('translation.TABLE_AVERAGE_LIKE'),
      ],
    ],
    body: [...top180],
  });
  doc.addPage();

  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(t('translation.HASHTAG_TOP_LIKE_POSTING'), 8.46, 28);
  doc
    .setFont('kopub', 'normal')
    .setFontSize((50 * 72) / 300)
    .setTextColor(85, 85, 85)
    .text(
      // '분석한 해시태그 내에서 좋아요를 가장 많이 받은 게시물 순위입니다.',
      t('translation.HASHTAG_TOP_LIKE_POSTING_TEXT'),
      8.46,
      35
    );
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 10 },
      1: { cellWidth: 20 },
      2: { cellWidth: 15 },
      3: { cellWidth: 15 },
      4: { cellWidth: 31 },
      5: { cellWidth: 25 },
      6: { cellWidth: 25 },
      7: { cellWidth: 25 },
      8: { cellWidth: 25 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        'NO',
        t('translation.TABLE_POSTING_DATE'),
        t('translation.TABLE_LIKE'),
        t('translation.TABLE_COMMENT'),
        t('translation.TABLE_POSTING_ACCOUNT'),
        t('translation.TABLE_FOLLOWER'),
        t('translation.TABLE_FOLLOW'),
        t('translation.TABLE_POST'),
        t('translation.TABLE_AVERAGE_LIKE'),
      ],
    ],
    body: [...topLike],
  });
  doc.addPage();

  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(t('translation.HASHTAG_TOP_COMMENT_POSTING'), 8.46, 28);
  doc
    .setFont('kopub', 'normal')
    .setFontSize((50 * 72) / 300)
    .setTextColor(85, 85, 85)
    .text(
      // '분석한 해시태그 내에서 댓글이 가장 많이 달린 게시물 순위입니다.',
      t('translation.HASHTAG_TOP_COMMENT_POSTING_TEXT'),
      8.46,
      35
    );
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 10 },
      1: { cellWidth: 20 },
      2: { cellWidth: 15 },
      3: { cellWidth: 15 },
      4: { cellWidth: 31 },
      5: { cellWidth: 25 },
      6: { cellWidth: 25 },
      7: { cellWidth: 25 },
      8: { cellWidth: 25 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        'NO',
        t('translation.TABLE_POSTING_DATE'),
        t('translation.TABLE_LIKE'),
        t('translation.TABLE_COMMENT'),
        t('translation.TABLE_POSTING_ACCOUNT'),
        t('translation.TABLE_FOLLOWER'),
        t('translation.TABLE_FOLLOW'),
        t('translation.TABLE_POST'),
        t('translation.TABLE_AVERAGE_LIKE'),
      ],
    ],
    body: [...topComment],
  });
  const pageCount = doc.getNumberOfPages();
  for (let i = 0; i < pageCount; i += 1) {
    doc.setPage(i);
    const pageNum = doc.getCurrentPageInfo().pageNumber;
    if (pageNum !== 1) {
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(`${t('translation.ANALYSIS_KEYWORD')} : ${keyword}`, 10, 10, {
          align: 'left',
        });
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(`${t('translation.ANALYSIS_STANDARD_DATE')} : ${date}`, 200, 10, {
          align: 'right',
        });
      doc.setDrawColor(232, 232, 232);
      doc.line(8.46, 14, 201.5, 14);

      doc.setFillColor(249, 249, 249);
      doc.rect(0, 287, 210, 10, 'F');
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(
          (doc.getCurrentPageInfo().pageNumber - 1).toString(),
          104.98,
          293,
          { align: 'center' }
        )
        .text(
          t('translation.POPULAR_POSTING_EXPOSURE_ACCOUNT_ANALYSIS'),
          200,
          293,
          { align: 'right' }
        );
    }
  }
  return doc;
}
/**
 * TOP계정정보 내부
 */
async function setTopAccountContent(
  doc: jsPDF,
  date: string,
  score: any,
  likeAverage: any,
  follower: any,
  post: any
) {
  /**
   * TOP 인기게시물 노출 계정
   */

  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(t('translation.TOP_POPULAR_POSTING_EXPOSURE_ACCOUNT'), 8.46, 28);
  doc
    .setFont('kopub', 'normal')
    .setTextColor(85, 85, 85)
    .setFontSize((50 * 72) / 300)
    .text(
      // '인스타그램 내에서 가장 노출이 잘 되고 있는 계정 순위 입니다.',
      t('translation.TOP_POPULAR_POSTING_EXPOSURE_ACCOUNT_TEXT'),
      8.46,
      35
    );
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 13 },
      1: { cellWidth: 40 },
      2: { cellWidth: 23 },
      3: { cellWidth: 23 },
      4: { cellWidth: 23 },
      5: { cellWidth: 23 },
      6: { cellWidth: 23 },
      7: { cellWidth: 23 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        t('translation.TABLE_RANKING'),
        t('translation.TABLE_ACCOUNT'),
        t('translation.TABLE_EXPOSURE_SCORE'),
        t('translation.TABLE_FOLLOWER'),
        t('translation.TABLE_FOLLOW'),
        t('translation.TABLE_POST'),
        t('translation.TABLE_DAY_POSTING_COUNT'),
        t('translation.TABLE_AVERAGE_LIKE'),
      ],
    ],
    body: [...score],
  });
  doc.addPage();
  /**
   * TOP 평균 좋아요 수 계정
   */
  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(t('translation.TOP_AVERAGE_LIKE_COUNT_ACCOUNT'), 8.46, 28);
  doc
    .setFont('kopub', 'normal')
    .setTextColor(85, 85, 85)
    .setFontSize((50 * 72) / 300)
    .text(
      // '인스타그램 내에서 가장 평균 좋아요 수가 높은 계정 순위 입니다.',
      t('translation.TOP_AVERAGE_LIKE_COUNT_ACCOUNT_TEXT'),
      8.46,
      35
    );
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 13 },
      1: { cellWidth: 60 },
      2: { cellWidth: 30 },
      3: { cellWidth: 30 },
      4: { cellWidth: 30 },
      5: { cellWidth: 30 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        t('translation.TABLE_RANKING'),
        t('translation.TABLE_ACCOUNT'),
        t('translation.TABLE_AVERAGE_LIKE'),
        t('translation.TABLE_FOLLOWER'),
        t('translation.TABLE_FOLLOW'),
        t('translation.TABLE_POST'),
      ],
    ],
    body: [...likeAverage],
  });
  doc.addPage();
  /**
   * TOP 팔로워 수 계정
   */
  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(t('translation.TOP_FOLLOWER_COUNT_ACCOUNT'), 8.46, 28);
  doc
    .setFont('kopub', 'normal')
    .setFontSize((50 * 72) / 300)
    .setTextColor(85, 85, 85)
    .text(t('translation.TOP_FOLLOWER_COUNT_ACCOUNT_TEXT'), 8.46, 35);
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 13 },
      1: { cellWidth: 60 },
      2: { cellWidth: 30 },
      3: { cellWidth: 30 },
      4: { cellWidth: 30 },
      5: { cellWidth: 30 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        t('translation.TABLE_RANKING'),
        t('translation.TABLE_ACCOUNT'),
        t('translation.TABLE_FOLLOWER'),
        t('translation.TABLE_FOLLOW'),
        t('translation.TABLE_POST'),
        t('translation.TABLE_AVERAGE_LIKE'),
      ],
    ],
    body: [...follower],
  });
  doc.addPage();
  /**
   * TOP 포스트 수 계정
   */
  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(t('translation.TOP_POST_COUNT_ACCOUNT'), 8.46, 28);
  doc
    .setFont('kopub', 'normal')
    .setFontSize((50 * 72) / 300)
    .setTextColor(85, 85, 85)
    .text(t('translation.TOP_POST_COUNT_ACCOUNT_TEXT'), 8.46, 35);
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 13 },
      1: { cellWidth: 60 },
      2: { cellWidth: 30 },
      3: { cellWidth: 30 },
      4: { cellWidth: 30 },
      5: { cellWidth: 30 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        t('translation.TABLE_RANKING'),
        t('translation.TABLE_ACCOUNT'),
        t('translation.TABLE_POST'),
        t('translation.TABLE_FOLLOWER'),
        t('translation.TABLE_FOLLOW'),
        t('translation.TABLE_AVERAGE_LIKE'),
      ],
    ],
    body: [...post],
  });
  const pageCount = doc.getNumberOfPages();
  for (let i = 0; i < pageCount; i += 1) {
    doc.setPage(i);
    const pageNum = doc.getCurrentPageInfo().pageNumber;
    if (pageNum !== 1) {
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(`${t('translation.ANALYSIS_STANDARD_DATE')} : ${date}`, 200, 10, {
          align: 'right',
        });
      doc.setDrawColor(232, 232, 232);
      doc.line(8.46, 14, 201.5, 14);

      doc.setFillColor(249, 249, 249);
      doc.rect(0, 287, 210, 10, 'F');
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(
          (doc.getCurrentPageInfo().pageNumber - 1).toString(),
          104.98,
          293,
          { align: 'center' }
        )
        .text(t('translation.TOP_ACCOUNT_INFORMATION'), 200, 293, {
          align: 'right',
        });
    }
  }
  return doc;
}
/**
 * HOT해시태그정보 내부
 */
async function setHotHashtagContent(
  doc: jsPDF,
  date: string,
  increaseRate: any,
  increaseAmountDaily: any,
  amount: any
) {
  /**
   * HOT게시물 증갸율
   */

  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(t('translation.HOT_HASHTAG_INFORMATION'), 8.46, 28);
  doc
    .setFont('kopub', 'normal')
    .setFontSize((50 * 72) / 300)
    .setTextColor(85, 85, 85)
    .text(t('translation.HOT_HASHTAG_INFORMATION_TEXT'), 8.46, 35);
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 30 },
      1: { cellWidth: 110 },
      2: { cellWidth: 53 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        t('translation.TABLE_RANKING'),
        t('translation.TABLE_HASHTAG'),
        t('translation.POSTING_INCREASE_RATE'),
      ],
    ],
    body: [...increaseRate],
  });
  doc.addPage();

  /**
   * HOT 게시물 1일 증가 수
   */
  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(t('translation.HOT_DAILY_POSTING_INCREASE_COUNT'), 8.46, 28);
  doc
    .setFont('kopub', 'normal')
    .setTextColor(85, 85, 85)
    .setFontSize((50 * 72) / 300)
    .text(t('translation.HOT_DAILY_POSTING_INCREASE_COUNT_TEXT'), 8.46, 35);
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 30 },
      1: { cellWidth: 110 },
      2: { cellWidth: 53 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        t('translation.TABLE_RANKING'),
        t('translation.TABLE_HASHTAG'),
        t('translation.TABLE_POSTING_INCREASE_COUNT'),
      ],
    ],
    body: [...increaseAmountDaily],
  });
  doc.addPage();
  /**
   * HOT게시물 수
   */
  doc
    .setFont('kopub', 'bold')
    .setTextColor(0, 0, 0)
    .setFontSize((70 * 72) / 300)
    .text(t('translation.HOT_POSTING_COUNT'), 8.46, 28);
  doc
    .setFont('kopub', 'normal')
    .setFontSize((50 * 72) / 300)
    .setTextColor(85, 85, 85)
    .text(t('translation.HOT_POSTING_COUNT_TEXT'), 8.46, 35);
  autoTable(doc, {
    styles: {
      font: 'kopub',
      fontStyle: 'normal',
      halign: 'center',
      valign: 'middle',
    },
    theme: 'plain',
    headStyles: {
      fontSize: (40 * 72) / 300,
      fontStyle: 'bold',
      fillColor: '#F1F5FE',
      minCellHeight: 15,
    },
    bodyStyles: {
      fontSize: (35 * 72) / 300,
      overflow: 'linebreak',
      minCellHeight: 11,
    },
    columnStyles: {
      0: { cellWidth: 30 },
      1: { cellWidth: 110 },
      2: { cellWidth: 53 },
    },
    startY: 40,
    margin: { top: 20, left: 9 },
    head: [
      [
        t('translation.TABLE_RANKING'),
        t('translation.TABLE_HASHTAG'),
        t('translation.TABLE_POSTING_COUNT'),
      ],
    ],
    body: [...amount],
  });

  const pageCount = doc.getNumberOfPages();
  for (let i = 0; i < pageCount; i += 1) {
    doc.setPage(i);
    const pageNum = doc.getCurrentPageInfo().pageNumber;
    if (pageNum !== 1) {
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(`${t('translation.ANALYSIS_STANDARD_DATE')} : ${date}`, 200, 10, {
          align: 'right',
        });
      doc.setDrawColor(232, 232, 232);
      doc.line(8.46, 14, 201.5, 14);

      doc.setFillColor(249, 249, 249);
      doc.rect(0, 287, 210, 10, 'F');
      doc
        .setTextColor(0, 0, 0)
        .setFontSize((35 * 72) / 300)
        .text(
          (doc.getCurrentPageInfo().pageNumber - 1).toString(),
          104.98,
          293,
          { align: 'center' }
        )
        .text(t('translation.HOT_HASHTAG_INFORMATION'), 200, 293, {
          align: 'right',
        });
    }
  }
  return doc;
}
export const useReport = () => {
  const [errorCode, setErrorCode] = useState('');
  const dispatch = useTypedDispatch();
  const [createReport] = useCreateReportMutation();
  const { companyName } = useTypedSelector(({ rootReducer }: RootState) => ({
    companyName: rootReducer.authReducers.companyName || '핫셀러',
    accessToken: rootReducer.authReducers.accessToken,
  }));

  /**
   * 스타태그 무료상품 작성
   */
  const handleStartagFree = async (
    keyword: string,
    recipient: string,
    date: string,
    tableData: any
  ) => {
    dispatch(changeIsReportDownloadState());
    const tags = tableData.map((row: any, i: number) => {
      let criteria = '';

      if (row.criteria === '단어포함태그') {
        criteria = t('translation.TABLE_COLLECTION_STANDARD_TYPE1');
      }

      if (row.criteria === '추천완성') {
        criteria = t('translation.TABLE_COLLECTION_STANDARD_TYPE2');
      }

      return [i + 1, criteria, row.hashtag, addCommaToNumber(row.post_cnt)];
    });
    const doc = new jsPDF('p', 'mm', [297, 210], true);
    let result = setDefault(doc);
    result = setFrontCover(
      result,
      serviceName.STARTAGFREE,
      date.substring(0, 10),
      keyword,
      companyName,
      recipient
    );
    result = await setStartagFreeContent(
      result,
      keyword,
      date.substring(0, 10),
      tags
    );
    result = setBackCover(result, companyName);
    const blobPDF = new Blob([result.output('blob')], {
      type: 'application/pdf',
    });
    const form = new FormData();
    form.append('upload_file', blobPDF);
    createReport({
      service: 'star_hashtag',
      form,
      keyword,
      recipient,
    })
      .unwrap()
      .then(res => {
        const fileNameYear = date.slice(0, 4);
        const fileNameMonth = date.slice(5, 7);
        const fileNameDate = date.slice(8, 10);
        download(
          res,
          `${fileNameYear}${fileNameMonth}${fileNameDate}_star_recommend_relation_${keyword}.pdf`
        );
        dispatch(changeIsReportDownloadState());
      })
      .catch(err => {
        dispatch(changeIsReportDownloadState());
        setErrorCode(err.data.code);
      });
  };
  /**
   * 스타태그 유료상품 작성
   */
  const handleStartagCharged = async (
    keyword: string,
    recipient: string,
    date: string,
    tableData: any
  ) => {
    dispatch(changeIsReportDownloadState());
    const tags = tableData.map((row: any) => {
      return [row.idx, row.criteria, row.hashtag, addCommaToNumber(row.post)];
    });
    const doc = new jsPDF('p', 'mm', [297, 210], true);
    let result = setDefault(doc);
    result = setFrontCover(
      result,
      serviceName.STARTAGCHARGED,
      date.substring(0, 10),
      keyword,
      companyName,
      recipient
    );
    result = await setStartagChargedContent(
      result,
      keyword,
      date.substring(0, 10),
      tags
    );
    result = setBackCover(result, companyName);
    const blobPDF = new Blob([result.output('blob')], {
      type: 'application/pdf',
    });
    const form = new FormData();
    form.append('upload_file', blobPDF);
    createReport({
      service: 'startag',
      form,
      keyword,
      recipient,
    })
      .unwrap()
      .then(res => {
        const fileNameYear = date.slice(0, 4);
        const fileNameMonth = date.slice(5, 7);
        const fileNameDate = date.slice(8, 10);
        download(
          res,
          `${fileNameYear}${fileNameMonth}${fileNameDate}_star_include_${keyword}.pdf`
        );
        dispatch(changeIsReportDownloadState());
      })
      .catch(err => {
        dispatch(changeIsReportDownloadState());
        setErrorCode(err.data.code);
      });
  };
  /**
   * HOT해시태그정보 작성
   */
  const handleExportHotHashtag = async (
    date: string,
    recipient: string,
    tableData: any
  ) => {
    dispatch(changeIsReportDownloadState());
    const doc = new jsPDF('p', 'mm', [297, 210], true);
    const increaseRateData = tableData.increase_rate.map((row: any) => {
      return [row.idx, row.hashtag, row.increase_rate];
    });
    const increaseAmountDailyData = tableData.increase_amount_daily.map(
      (row: any) => {
        return [
          row.idx,
          row.hashtag,
          addCommaToNumber(row.increase_amount_daily),
        ];
      }
    );
    const amountData = tableData.amount.map((row: any) => {
      return [row.idx, row.hashtag, addCommaToNumber(row.amount)];
    });
    let result = setDefault(doc);
    result = setFrontCover(
      result,
      serviceName.HOT,
      date.substring(0, 10),
      null,
      companyName,
      recipient
    );
    result = await setHotHashtagContent(
      result,
      date.substring(0, 10),
      increaseRateData,
      increaseAmountDailyData,
      amountData
    );
    result = setBackCover(result, companyName);

    const blobPDF = new Blob([result.output('blob')], {
      type: 'application/pdf',
    });
    const form = new FormData();
    form.append('upload_file', blobPDF);
    createReport({
      service: 'hot',
      form,
      keyowrd: null,
      recipient,
    })
      .unwrap()
      .then(res => {
        const fileNameYear = date.slice(0, 4);
        const fileNameMonth = date.slice(5, 7);
        const fileNameDate = date.slice(8, 10);
        download(
          res,
          `${fileNameYear}${fileNameMonth}${fileNameDate}_HOT_hashtag.pdf`
        );
        dispatch(changeIsReportDownloadState());
      })
      .catch(err => {
        dispatch(changeIsReportDownloadState());
        setErrorCode(err.data.code);
      });
  };
  /**
   * TOP계정정보 작성
   */
  const handleExportTopAccount = async (
    date: string,
    recipient: string,
    tableData: any
  ) => {
    dispatch(changeIsReportDownloadState());
    const doc = new jsPDF('p', 'mm', [297, 210], true);
    const scoreData = tableData.score.map((row: any) => {
      return [
        row.idx,
        row.account,
        addCommaToNumber(row.score),
        addCommaToNumber(row.follower),
        addCommaToNumber(row.follow),
        addCommaToNumber(row.post),
        row.post_daily,
        addCommaToNumber(row.like_average),
      ];
    });
    const likeAverageData = tableData.like_average.map((row: any) => {
      return [
        row.idx,
        row.account,
        addCommaToNumber(row.like_average),
        addCommaToNumber(row.follower),
        addCommaToNumber(row.follow),
        addCommaToNumber(row.post),
      ];
    });
    const followerData = tableData.follower.map((row: any) => {
      return [
        row.idx,
        row.account,
        addCommaToNumber(row.follower),
        addCommaToNumber(row.follow),
        addCommaToNumber(row.post),
        addCommaToNumber(row.like_average),
      ];
    });
    const postData = tableData.post.map((row: any) => {
      return [
        row.idx,
        row.account,
        addCommaToNumber(row.post),
        addCommaToNumber(row.follower),
        addCommaToNumber(row.follow),
        addCommaToNumber(row.like_average),
      ];
    });
    let result = setDefault(doc);
    result = setFrontCover(
      result,
      serviceName.TOP,
      date.substring(0, 10),
      null,
      companyName,
      recipient
    );
    result = await setTopAccountContent(
      result,
      date.substring(0, 10),
      scoreData,
      likeAverageData,
      followerData,
      postData
    );
    result = setBackCover(result, companyName);

    const blobPDF = new Blob([result.output('blob')], {
      type: 'application/pdf',
    });
    const form = new FormData();
    form.append('upload_file', blobPDF);
    createReport({
      service: 'top',
      form,
      keyword: null,
      recipient,
    })
      .unwrap()
      .then(res => {
        const fileNameYear = date.slice(0, 4);
        const fileNameMonth = date.slice(5, 7);
        const fileNameDate = date.slice(8, 10);
        download(
          res,
          `${fileNameYear}${fileNameMonth}${fileNameDate}_TOP_account.pdf`
        );
        dispatch(changeIsReportDownloadState());
      })
      .catch(err => {
        dispatch(changeIsReportDownloadState());
        setErrorCode(err.data.code);
      });
  };
  /**
   * 계정분석 작성
   */
  const handleAccount = async (
    keyword: string,
    date: string,
    recipient: string,
    accountBasicInfo: any,
    hashtags: any,
    chartDates: any,
    accountSuitability: any
  ) => {
    dispatch(changeIsReportDownloadState());
    const charts = Array.from(document.getElementsByClassName('chartLibrary'));
    const detailSuitability = document.getElementById('detailSuitability');

    const { profileImg } = accountBasicInfo;

    const doc = new jsPDF('p', 'mm', [297, 210], true);
    let result = setDefault(doc);
    result = setFrontCover(
      result,
      serviceName.ACCOUNT,
      date.substring(0, 10),
      keyword,
      companyName,
      recipient
    );
    result = await setAccountContent(
      result,
      keyword,
      date.substring(0, 10),
      accountBasicInfo,
      accountSuitability,
      hashtags,
      {
        charts,
        detailSuitability,
      },
      chartDates,
      serviceName.ACCOUNT,
      profileImg
    );
    result = setBackCover(result, companyName);

    const blobPDF = new Blob([result.output('blob')], {
      type: 'application/pdf',
    });
    const form = new FormData();
    form.append('upload_file', blobPDF);
    createReport({
      service: 'account',
      form,
      keyword,
      recipient,
    })
      .unwrap()
      .then(res => {
        const fileNameYear = date.slice(0, 4);
        const fileNameMonth = date.slice(5, 7);
        const fileNameDate = date.slice(8, 10);
        download(
          res,
          `${fileNameYear}${fileNameMonth}${fileNameDate}_account_analysis_${keyword}.pdf`
        );
        dispatch(changeIsReportDownloadState());
      })
      .catch(err => {
        dispatch(changeIsReportDownloadState());
        setErrorCode(err.data.code);
      });
  };
  /**
   * 인기게시물 노출계정 분석 작성
   */
  const handleExposedAccount = async (
    keyword: string,
    date: string,
    recipient: string,
    tableData: any
  ) => {
    dispatch(changeIsReportDownloadState());
    const top7 = tableData.top_id_7.map((row: any) => {
      return [
        row.idx,
        addCommaToNumber(row.score),
        row.account,
        addCommaToNumber(row.follower),
        addCommaToNumber(row.follow),
        addCommaToNumber(row.post),
        addCommaToNumber(row.like_average),
      ];
    });
    const top30 = tableData.top_id_30.map((row: any) => {
      return [
        row.idx,
        addCommaToNumber(row.score),
        row.account,
        addCommaToNumber(row.follower),
        addCommaToNumber(row.follow),
        addCommaToNumber(row.post),
        addCommaToNumber(row.like_average),
      ];
    });
    const top90 = tableData.top_id_90.map((row: any) => {
      return [
        row.idx,
        addCommaToNumber(row.score),
        row.account,
        addCommaToNumber(row.follower),
        addCommaToNumber(row.follow),
        addCommaToNumber(row.post),
        addCommaToNumber(row.like_average),
      ];
    });
    const top180 = tableData.top_id_180.map((row: any) => {
      return [
        row.idx,
        addCommaToNumber(row.score),
        row.account,
        addCommaToNumber(row.follower),
        addCommaToNumber(row.follow),
        addCommaToNumber(row.post),
        addCommaToNumber(row.like_average),
      ];
    });
    const topLike = tableData.top_like.map((row: any) => {
      return [
        row.idx,
        row.posting_date.substring(0, 10),
        addCommaToNumber(row.like),
        row.comment,
        row.account,
        addCommaToNumber(row.follower),
        addCommaToNumber(row.follow),
        addCommaToNumber(row.post),
        addCommaToNumber(row.like_average),
      ];
    });
    const topComment = tableData.top_comment.map((row: any) => {
      return [
        row.idx,
        row.posting_date.substring(0, 10),
        addCommaToNumber(row.like),
        row.comment,
        row.account,
        addCommaToNumber(row.follower),
        addCommaToNumber(row.follow),
        addCommaToNumber(row.post),
        addCommaToNumber(row.like_average),
      ];
    });
    const doc = new jsPDF('p', 'mm', [297, 210], true);
    let result = setDefault(doc);
    result = setFrontCover(
      result,
      serviceName.EXPOSED,
      date.substring(0, 10),
      keyword,
      companyName,
      recipient
    );
    result = await setExposedContent(
      result,
      keyword,
      date.substring(0, 10),
      top7,
      top30,
      top90,
      top180,
      topLike,
      topComment
    );
    result = setBackCover(result, companyName);

    const blobPDF = new Blob([result.output('blob')], {
      type: 'application/pdf',
    });
    const form = new FormData();
    form.append('upload_file', blobPDF);
    createReport({
      service: 'exposed_account',
      form,
      keyword,
      recipient,
    })
      .unwrap()
      .then(res => {
        const fileNameYear = date.slice(0, 4);
        const fileNameMonth = date.slice(5, 7);
        const fileNameDate = date.slice(8, 10);
        download(
          res,
          `${fileNameYear}${fileNameMonth}${fileNameDate}_popular_exposure_${keyword}.pdf`
        );
        dispatch(changeIsReportDownloadState());
      })
      .catch(err => {
        dispatch(changeIsReportDownloadState());
        setErrorCode(err.data.code);
      });
  };
  /**
   * 게시물증가추이 작성
   */
  const handleExportPostIncrease = async (
    keyword: string,
    date: string,
    recipient: string,
    postCnt: string,
    increaseCnt: string
  ) => {
    dispatch(changeIsReportDownloadState());
    const charts = Array.from(document.getElementsByClassName('chartLibrary'));
    const doc = new jsPDF('p', 'mm', [297, 210], true);

    let result = setDefault(doc);
    result = setFrontCover(
      result,
      serviceName.POST,
      date.substring(0, 10),
      keyword,
      companyName,
      recipient
    );
    result = await setPostIncreaseContent(
      result,
      keyword,
      date.substring(0, 10),
      charts,
      postCnt.toString(),
      increaseCnt.toString()
    );
    result = setBackCover(result, companyName);

    const blobPDF = new Blob([result.output('blob')], {
      type: 'application/pdf',
    });
    const form = new FormData();
    form.append('upload_file', blobPDF);
    createReport({
      service: 'post_increase',
      form,
      keyword,
      recipient,
    })
      .unwrap()
      .then(res => {
        const fileNameYear = date.slice(0, 4);
        const fileNameMonth = date.slice(5, 7);
        const fileNameDate = date.slice(8, 10);
        download(
          res,
          `${fileNameYear}${fileNameMonth}${fileNameDate}_posting_transition_${keyword}.pdf`
        );
        dispatch(changeIsReportDownloadState());
      })
      .catch(err => {
        dispatch(changeIsReportDownloadState());
        setErrorCode(err.data.code);
      });
  };

  return {
    exportExposedAccount: handleExposedAccount,
    exportStartagFree: handleStartagFree,
    exportStartagCharged: handleStartagCharged,
    exportAccount: handleAccount,
    exportPostIncrease: handleExportPostIncrease,
    exportTopAccount: handleExportTopAccount,
    exportHotHashtag: handleExportHotHashtag,
    errorCode,
    setErrorCode,
  };
};
