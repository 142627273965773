import type { FC } from 'react';
import { ButtonHTMLAttributes } from 'react';
import * as S from './styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  isActive: boolean;
}

export const TableTabButton: FC<Props> = ({ name, isActive, ...props }) => {
  return (
    <S.TableTabButton
      backgroundColor={isActive ? '#8D97FF' : '#FFFFFF'}
      color={isActive ? '#FFFFFF' : '#A0A5B7'}
      {...props}
    >
      {name}
    </S.TableTabButton>
  );
};
