import {
  useGetStartagFreeItemMutation,
  useGetNewChargedItemMutation,
  useGetExistChargedItemMutation,
  useGetNewAccountItemMutation,
} from '@features/api';

export const useServiceApi = () => {
  const [getStartagFreeItem, getStartagFreeItemState] =
    useGetStartagFreeItemMutation();
  const [getNewChargedItem, getNewChargedItemState] =
    useGetNewChargedItemMutation();
  const [getNewAccountItem, getNewAccountItemState] =
    useGetNewAccountItemMutation();
  const [getExistChargedItem, getExistChargedItemState] =
    useGetExistChargedItemMutation();

  const getStartagFree = async (hashtag: string) => {
    const responseData = await getStartagFreeItem({
      hashtag,
    })
      .unwrap()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });

    return responseData;
  };
  const getNew = async (category: string, hashtag: string) => {
    const responseData = await getNewChargedItem({
      category,
      hashtag,
    })
      .unwrap()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });

    return responseData;
  };
  const getNewAccount = async (username: string) => {
    const responseData = await getNewAccountItem({
      username,
    })
      .unwrap()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
    return responseData;
  };
  const getExist = async (order_info: string) => {
    const responseData = await getExistChargedItem({
      order_info,
    })
      .unwrap()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });

    return responseData;
  };
  return {
    getStartagFree,
    getStartagFreeItemState,
    getNew,
    getNewChargedItemState,
    getNewAccount,
    getNewAccountItemState,
    getExist,
    getExistChargedItemState,
  };
};
