import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

interface Props {
  dates: string[] | undefined;
  values: string[] | number[] | undefined;
}

export const BarChart = ({ dates, values }: Props) => {
  const [series, setSeries] = useState<any>([
    {
      name: 'bar chart',
      data: values,
    },
  ]);
  const [options, setOptions] = useState({
    chart: {
      type: 'bar' as const,
      width: '100%',
      toolbar: { show: false },
      animations: {
        enabled: false,
        speed: 1000,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '20px',
        borderRadius: 7,
        dataLabels: {
          position: 'top',
        },
      },
    },
    colors: ['#8D97FF'],
    dataLabels: {
      offsetY: -30,
      style: {
        colors: ['#000000'],
      },
    },
    stroke: {
      show: true,
      colors: ['transparent'],
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.3,
        inverseColors: true,
      },
    },
    xaxis: {
      categories: dates,
      labels: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
  });
  useEffect(() => {
    setTimeout(() => {
      setSeries([
        {
          name: 'bar chart',
          data: values,
        },
      ]);
      setOptions({
        chart: {
          type: 'bar' as const,
          width: '100%',
          toolbar: { show: false },
          animations: {
            enabled: false,
            speed: 1000,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '20px',
            borderRadius: 7,
            dataLabels: {
              position: 'top',
            },
          },
        },
        colors: ['#8D97FF'],
        dataLabels: {
          offsetY: -30,
          style: {
            colors: ['#000000'],
          },
        },
        stroke: {
          show: true,
          colors: ['transparent'],
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'vertical',
            shadeIntensity: 0.3,
            inverseColors: true,
          },
        },
        xaxis: {
          categories: dates,
          labels: {
            show: true,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
          active: {
            filter: {
              type: 'none',
            },
          },
        },
      });
    }, 100);
  }, [dates, values]);
  return (
    <div id="chart" className="chartLibrary">
      <Chart type="bar" height={431} options={options} series={series} />
    </div>
  );
};
