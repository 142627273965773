export const COLOR = {
  primary: {
    first: '#4352e6',
    second: '#4A5BFD',
    third: '#8D97FF',
    fourth: '#C5CAFF',
  },
  secondary: {},
  tertiary: {},
  functional: {
    loginFormError: '#A1FCFF',
    registrationFormError: '#FF0000',
  },
  neutral: {
    first: '#555555',
    second: '#A0A5B7',
    third: '#C4C1D6',
    fourth: '#CECECE',
    fifth: '#F9FBFF',
    sixth: '#F1F5FE',
  },
  white: '#ffffff',
  black: {
    default: '#000',
    transparent: 'rgba(0, 0, 0, 0.7)',
  },
};

export const FONT_SIZE = {
  title: 40,
  subTitle: 38,
  xxl: 22,
  xl: 20,
  l: 18,
  m: 16,
  s: 14,
};

export const FONT_WEIGHT = {
  light: 300,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
};
