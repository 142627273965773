import styled from 'styled-components';

export const PublicButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 10px;
  background-color: #4a5bfd;
  color: #fff;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 20px #8d97ff;
  }
`;
export const PublicSecondButton = styled.button``;
export const PublicThirdButton = styled.button`
  min-width: 100px;
  /* height: 34px; */
  border: 0;
  border: 1px solid #cecece;
  background-color: #fff;
  font-weight: bold;
  font-size: 16px;
  padding: 3px 4px;
  cursor: pointer;
`;
export const NavButton = styled.button<ButtonStyle>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 14px;
  width: 75%;
  height: 44px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 0;
  border-radius: 10px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => `${theme.fontSize.m}px`};
  color: ${({ color }) => color};
  cursor: pointer;
`;
export const TableTabButton = styled.button<ButtonStyle>`
  position: relative;
  z-index: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 36px;
  border: 0;
  border-radius: 20px 20px 0 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.1);
  font-size: ${({ theme }) => `${theme.fontSize.m}px`};
  color: ${({ color }) => color};
  cursor: pointer;
  &:hover {
    color: #ffffff;
    background-color: #8d97ff;
  }
`;
export const OptionButton = styled.button<ButtonStyle>`
  width: 100px;
  height: 30px;
  border: 0;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
  color: #555555;
  font-size: 15px;
`;
export const Gap = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}px;`};
  height: 100%;
`;
