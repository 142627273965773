import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import { useTranslation } from 'react-i18next';

const CustomTooltip = ({ active, payload, label }: any) => {
  const { t } = useTranslation();

  if (active) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          padding: '0 10px',
          width: '100%',
          border: '1px solid #cecece',
          borderRadius: '5px',
          backgroundColor: '#fff',
          boxShadow: '2px 2px 3px #cecece',
        }}
      >
        <p className="label">{`${label}`}</p>
        <p>{`${t('translation.POST_COUNT')} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};
export const RechartLineChart = ({ data }: any) => {
  return (
    <div className="chartLibrary">
      <LineChart
        width={1370}
        height={430}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="date"
          interval="preserveStartEnd"
          padding={{ left: 20, right: 20 }}
          minTickGap={50}
          dy={15}
        />
        <YAxis type="number" domain={['auto', 'auto']} />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          dataKey="value"
          strokeWidth={3}
          dot={false}
          stroke="#0058FF"
        />
      </LineChart>
    </div>
  );
};
