import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from '@features/models/states';

const initialState: AuthState = {
  accessToken: null,
  serviceApiCallDetectCount: 0,
  companyName: null,
};
const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    updateAccessToken(state: AuthState, action: PayloadAction<string | null>) {
      state.accessToken = action.payload;
    },
    logout(state: AuthState) {
      state.accessToken = null;
    },
    increaseServiceApiCallDetectCount(state: AuthState) {
      state.serviceApiCallDetectCount += 1;
    },
    setCompanyName(state: AuthState, action: PayloadAction<string | null>) {
      state.companyName = action.payload;
    },
  },
});

export const {
  updateAccessToken,
  logout,
  increaseServiceApiCallDetectCount,
  setCompanyName,
} = authSlice.actions;
export const authReducers = authSlice.reducer;
