import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
`;
export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 500;
`;
export const StyledModal = styled.div<{ width: number; height: number }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 100;
  margin: auto;
  padding: 40px 0 20px;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  border-radius: 20px;
  background: white;
`;
export const Content = styled.div`
  max-height: 30rem;
  overflow-x: hidden;
  overflow-y: auto;
`;
export const Messages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Message = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
`;
export const SubMessage = styled.div`
  font-size: 16px;
  & > p {
    text-align: center;
  }
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;
export const FirstButton = styled.button<{ isDisabled: boolean }>`
  width: 200px;
  height: 50px;
  border: 0;
  border-radius: 10px;
  background-color: ${({ isDisabled }) => (isDisabled ? '#CECECE' : '#4A5BFD')};
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  :hover {
    background-color: ${({ isDisabled }) =>
      isDisabled ? '#CECECE' : '#8D97FF'};
  }
`;
export const SecondButton = styled.button<{ isDisabled: boolean }>`
  width: 200px;
  height: 50px;
  border: 0;
  border-radius: 10px;
  background-color: ${({ isDisabled }) => (isDisabled ? '#CECECE' : '#4A5BFD')};
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  :hover {
    background-color: ${({ isDisabled }) =>
      isDisabled ? '#CECECE' : '#8D97FF'};
  }
`;
