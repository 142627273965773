/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, useRef } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import TooltipIcon from 'assets/images/tooltipicon@2x.png';
import { Modal } from '@atoms/utils';
import { tableCodes as CODE, STARTAG } from '@constants';
import { openNewTab, addCommaToNumber } from '@utils';
import { useModal } from '@hooks';
import * as S from './BasicTable.style';

const THead = ({ code, columns }: { code: string; columns: string[] }) => {
  const { t } = useTranslation();

  return (
    <S.THead code={code}>
      <S.TR>
        {columns.map((column, i) => {
          if (code === CODE.SFV && i === 1) {
            return (
              <S.TH key={nanoid()}>
                <a
                  style={{ fontWeight: 'inherit' }}
                  data-tip
                  data-for="description"
                >
                  {column}
                </a>
                <ReactTooltip
                  id="description"
                  effect="solid"
                  place="right"
                  backgroundColor="#EFF1FF"
                  textColor="#4A5BFD"
                  border
                  borderColor="#4A5BFD"
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <img
                      style={{ width: '24px', height: '24px' }}
                      src={TooltipIcon}
                      alt="icon"
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginLeft: '4px',
                      }}
                    >
                      <div>
                        {t('translation.TABLE_SCORE_TOOL_TIP1')}
                        {/* 추천완성: 해당 키워드와의 유사성을 측정한 점수입니다. */}
                      </div>
                      <div>
                        {t('translation.TABLE_SCORE_TOOL_TIP2')}
                        {/* 연관해시태그: 해당 태그와 같이 쓰이는 정도를 측정한
                        점수입니다. */}
                      </div>
                    </div>
                  </div>
                </ReactTooltip>
              </S.TH>
            );
          }
          if (code === CODE.TE && i === 1) {
            return (
              <S.TH key={nanoid()}>
                <a
                  style={{ fontWeight: 'inherit' }}
                  data-tip
                  data-for="description"
                >
                  {column}
                </a>
                <ReactTooltip
                  id="description"
                  effect="solid"
                  place="right"
                  backgroundColor="#EFF1FF"
                  textColor="#4A5BFD"
                  border
                  borderColor="#4A5BFD"
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <img
                      style={{ width: '24px', height: '24px' }}
                      src={TooltipIcon}
                      alt="icon"
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginLeft: '4px',
                      }}
                    >
                      <div>
                        {t('translation.TABLE_EXPOSURE_SCORE_TOOL_TIP')}
                        {/* 인스타그램 전체에서의 누적 활동을 측정한 점수입니다. */}
                      </div>
                    </div>
                  </div>
                </ReactTooltip>
              </S.TH>
            );
          }
          if (code === CODE.ESV && i === 1) {
            return (
              <S.TH key={nanoid()}>
                <a
                  style={{ fontWeight: 'inherit' }}
                  data-tip
                  data-for="description"
                >
                  {column}
                </a>
                <ReactTooltip
                  id="description"
                  effect="solid"
                  place="right"
                  backgroundColor="#EFF1FF"
                  textColor="#4A5BFD"
                  border
                  borderColor="#4A5BFD"
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <img
                      style={{ width: '24px', height: '24px' }}
                      src={TooltipIcon}
                      alt="icon"
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginLeft: '4px',
                      }}
                    >
                      <div>
                        {t('translation.ACTIVITY_AMOUNT_TOOL_TIP')}
                        {/* 해당 해시태그, 해당 기간동안의 활동량을 측정한 점수입니다. */}
                      </div>
                    </div>
                  </div>
                </ReactTooltip>
              </S.TH>
            );
          }
          return <S.TH key={nanoid()}>{column}</S.TH>;
        })}
      </S.TR>
    </S.THead>
  );
};

interface TBodyProps {
  code: string;
  data: Record<string, string | number>[] | undefined;
  height: number;
}

const TBody = ({ code, data, height }: TBodyProps) => {
  const tableData = data;
  const { t } = useTranslation();

  if (code.includes('TopAccounts')) {
    return (
      <S.TBody code={code} height={height}>
        {tableData &&
          tableData.map((row: Record<string, string | number>) => {
            return (
              <S.TR
                key={nanoid()}
                onClick={() => openNewTab(row.url as string)}
              >
                <S.TD>{row.idx}</S.TD>
                {code === CODE.TE && row.score && (
                  <S.TD>{addCommaToNumber(row.score)}</S.TD>
                )}
                <S.TD>{row.account}</S.TD>
                <S.TD>{addCommaToNumber(row.follower)}</S.TD>
                <S.TD>{addCommaToNumber(row.follow)}</S.TD>
                <S.TD>{addCommaToNumber(row.post)}</S.TD>
                {code === CODE.TE && row.score && <S.TD>{row.post_daily}</S.TD>}
                <S.TD>{addCommaToNumber(row.like_average)}</S.TD>
              </S.TR>
            );
          })}
      </S.TBody>
    );
  }
  if (code.includes('HotHashtag')) {
    return (
      <S.TBody code={code} height={height}>
        {tableData &&
          tableData.map((row: Record<string, string | number>) => {
            const value = row.increase_rate
              ? row.increase_rate
              : row.increase_amount_daily
              ? addCommaToNumber(row.increase_amount_daily)
              : addCommaToNumber(row.amount);
            return (
              <S.TR
                key={nanoid()}
                onClick={() => openNewTab(row.url as string)}
              >
                <S.TD>{row.idx}</S.TD>
                <S.TD>{row.hashtag}</S.TD>
                <S.TD>{value}</S.TD>
              </S.TR>
            );
          })}
      </S.TBody>
    );
  }
  if (code === CODE.ESV) {
    return (
      <S.TBody code={code} height={height}>
        {tableData &&
          tableData.map((row: Record<string, string | number>) => {
            return (
              <S.TR
                key={nanoid()}
                onClick={() => openNewTab(row.url as string)}
              >
                <S.TD>{row.idx}</S.TD>
                <S.TD>{addCommaToNumber(row.score)}</S.TD>
                <S.TD>{row.account}</S.TD>
                <S.TD>{addCommaToNumber(row.follower)}</S.TD>
                <S.TD>{addCommaToNumber(row.follow)}</S.TD>
                <S.TD>{addCommaToNumber(row.post)}</S.TD>
                <S.TD>{addCommaToNumber(row.like_average)}</S.TD>
              </S.TR>
            );
          })}
      </S.TBody>
    );
  }
  if (code === CODE.EN) {
    return (
      <S.TBody code={code} height={height}>
        {tableData &&
          tableData.map((row: Record<string, string | number>) => {
            return (
              <S.TR
                key={nanoid()}
                onClick={() => openNewTab(row.url as string)}
              >
                <S.TD>{row.idx}</S.TD>
                <S.TD>{(row.posting_date as string).substring(0, 10)}</S.TD>
                <S.TD>{addCommaToNumber(row.like)}</S.TD>
                <S.TD>{addCommaToNumber(row.comment)}</S.TD>
                <S.TD>{row.account}</S.TD>
                <S.TD>{addCommaToNumber(row.follower)}</S.TD>
                <S.TD>{addCommaToNumber(row.follow)}</S.TD>
                <S.TD>{addCommaToNumber(row.post)}</S.TD>
                <S.TD>{addCommaToNumber(row.like_average)}</S.TD>
              </S.TR>
            );
          })}
      </S.TBody>
    );
  }

  if (code === CODE.SF) {
    return (
      <S.TBody code={code} height={height}>
        {tableData &&
          tableData.map(
            (row: Record<string, string | number>, index: number) => {
              const postCnt =
                typeof row.post_cnt === 'number' ? row.post_cnt : row.post;
              return (
                <S.TR
                  key={nanoid()}
                  onClick={() => openNewTab(row.url as string)}
                >
                  <S.TD>{index + 1}</S.TD>
                  <S.TD>
                    {row.criteria === '단어포함태그' &&
                      t('translation.TABLE_COLLECTION_STANDARD_TYPE1')}
                    {row.criteria === '추천완성' &&
                      t('translation.TABLE_COLLECTION_STANDARD_TYPE2')}
                  </S.TD>
                  <S.TD>{row.hashtag}</S.TD>
                  <S.TD>{addCommaToNumber(postCnt)}</S.TD>
                </S.TR>
              );
            }
          )}
      </S.TBody>
    );
  }
  return (
    <S.TBody code={code} height={height}>
      {tableData &&
        tableData.map((row: Record<string, string | number>, index: number) => {
          const postCnt =
            typeof row.post_cnt === 'number' ? row.post_cnt : row.post;
          return (
            <S.TR key={nanoid()} onClick={() => openNewTab(row.url as string)}>
              <S.TD>{index + 1}</S.TD>
              <S.TD>{addCommaToNumber(row.score || 0)}</S.TD>
              <S.TD>
                {row.criteria === '단어포함태그' &&
                  t('translation.TABLE_COLLECTION_STANDARD_TYPE1')}
                {row.criteria === '추천완성' &&
                  t('translation.TABLE_COLLECTION_STANDARD_TYPE2')}
              </S.TD>
              <S.TD>{row.hashtag}</S.TD>
              <S.TD>{addCommaToNumber(postCnt)}</S.TD>
            </S.TR>
          );
        })}
    </S.TBody>
  );
};

interface Props {
  tableData: TableSet | null;
  tbodyHeight: number;
}

export const BasicTable: FC<Props> = ({ tableData, tbodyHeight }) => {
  const { t } = useTranslation();
  const { isShown, modalContent, onModalOpen, onModalClose } = useModal();

  const filterMin = useRef<any>('');
  const filterMax = useRef<any>('');

  const [rows, setRows] = useState<any>(tableData?.data);
  const [isFilterContain] = useState<boolean>(() => {
    if (
      tableData?.title ===
      `${t('translation.RECOMMEND_AUTO_COMPLETE')}, ${t(
        'translation.RELATED_HASHTAG'
      )}`
    )
      return true;
    if (tableData?.title === t('translation.WORD_INCLUDE_HASHTAG')) return true;
    return false;
  });

  const [filterValues, setFilterValues] = useState<Record<string, number>>(
    () => {
      let postCntArr: any = [];
      if (
        tableData?.title ===
        `${t('translation.RECOMMEND_AUTO_COMPLETE')}, ${t(
          'translation.RELATED_HASHTAG'
        )}`
      ) {
        postCntArr = tableData?.data?.map((data: any) => data.post_cnt);
      }
      if (tableData?.title === t('translation.WORD_INCLUDE_HASHTAG')) {
        postCntArr = tableData?.data?.map((data: any) => data.post);
      }
      return {
        min: Math.min(...postCntArr) || 0,
        max: Math.max(...postCntArr) || 0,
      };
    }
  );
  useEffect(() => {
    setRows(tableData?.data);
  }, [tableData]);

  const handleFilterChange = () => {
    let postCntArr;
    if (tableData) {
      if (
        tableData.data &&
        tableData.title ===
          `${t('translation.RECOMMEND_AUTO_COMPLETE')}, ${t(
            'translation.RELATED_HASHTAG'
          )}`
      ) {
        postCntArr = tableData.data.map((data: any) => data.post_cnt);
      }
      if (
        tableData.data &&
        tableData.title === t('translation.WORD_INCLUDE_HASHTAG')
      ) {
        postCntArr = tableData.data.map((data: any) => data.post);
      }
    }
    const fillMinAndMaxValues = {
      min: +filterMin.current.value || Math.min(...(postCntArr as any)),
      max: +filterMax.current.value || Math.max(...(postCntArr as any)),
    };
    setFilterValues(fillMinAndMaxValues);
  };

  useEffect(() => {
    if (isFilterContain && filterValues && tableData) {
      setRows(
        tableData?.data?.filter(datum => {
          if (datum.post_cnt)
            return (
              Number(datum.post_cnt) >= filterValues.min &&
              Number(datum.post_cnt) <= filterValues.max
            );
          if (datum.post)
            return (
              Number(datum.post) >= filterValues.min &&
              Number(datum.post) <= filterValues.max
            );
          return false;
        })
      );
    }
  }, [filterValues]);

  useEffect(() => {
    if (tableData) {
      if (
        tableData.title ===
          `${t('translation.RECOMMEND_AUTO_COMPLETE')}, ${t(
            'translation.RELATED_HASHTAG'
          )}` ||
        tableData.title === t('translation.WORD_INCLUDE_HASHTAG')
      ) {
        if (rows && rows.length === 0) {
          onModalOpen({
            onFirstButtonClick: onModalClose,
            message: t('translation.NO_SEARCH_RANGE_TITLE'),
            subMessage: [
              t('translation.NO_SEARCH_RANGE_TEXT1'),
              t('translation.NO_SEARCH_RANGE_TEXT2'),
            ],
            firstButtonText: t('translation.OK_BUTTON'),
          });
        }
      }
    }
  }, [rows]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        {isFilterContain && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              top: '-40px',
              right: 0,
            }}
          >
            <input
              style={{
                padding: '0 10px',
                width: '160px',
                height: '28px',
                border: '1px solid #cecece',
                outline: 'none',
              }}
              placeholder={t('translation.MIN_POSTING_COUNT')}
              ref={filterMin}
            />
            ~
            <input
              style={{
                padding: '0 10px',
                width: '160px',
                height: '28px',
                border: '1px solid #cecece',
                outline: 'none',
              }}
              placeholder={t('translation.MAX_POSTING_COUNT')}
              ref={filterMax}
            />
            <S.FilterButton onClick={handleFilterChange}>
              <img
                style={{
                  width: '18px',
                  height: '18px',
                }}
                src={STARTAG.filtersearch}
                alt="search button"
              />
            </S.FilterButton>
          </div>
        )}

        <S.Table>
          {tableData && rows && (
            <>
              <THead code={tableData.tableCode} columns={tableData.columns} />
              {rows.length === 0 &&
              tableData.title !==
                `${t('translation.RECOMMEND_AUTO_COMPLETE')}, ${t(
                  'translation.RELATED_HASHTAG'
                )}` &&
              tableData.title !== t('translation.WORD_INCLUDE_HASHTAG') ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    left: '50%',
                    top: '170px',
                    transform: 'translateX(-50%)',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '5px',
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    {t('translation.TABLE_NO_LIST_TITLE')}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <p>{t('translation.TABLE_NO_LIST_TEXT1')}</p>
                    <p>{t('translation.TABLE_NO_LIST_TEXT2')}</p>
                  </div>
                </div>
              ) : (
                <TBody
                  code={tableData.tableCode}
                  data={rows}
                  height={tbodyHeight}
                />
              )}
            </>
          )}
        </S.Table>
      </div>
      <Modal
        isShown={isShown}
        modalContent={modalContent}
        onClose={onModalClose}
      />
    </>
  );
};
