import { MAININITTEMPLATE_INFO_ICONS as ICON } from '@constants';

export const STARTAG_DESCRIPTION = {
  iconName: 'Startag',
  title: '스타태그',
  info: [
    {
      iconName: ICON.startag,
      title: '스타태그란?',
      content:
        '검색한 해시태그와 함께/사용하면 더욱 효과적인/연관 해시태그 추천 서비스',
    },
    {
      iconName: ICON.targetuser,
      title: '추천 사용자',
      content:
        '원하는 분야 내 실제로/많이 쓰는 해시태그를 선별해,/전략적 마케팅을 하고 싶은 분',
    },
    {
      iconName: ICON.analysis,
      title: '분석항목',
      content: '- 추천 자동완성/- 연관 해시태그/- 단어포함 해시태그',
    },
  ],
};

export const EXPOSEDACCOUNT_DESCRIPTION = {
  iconName: 'ExposedAccount',
  title: '인기게시물 노출 계정 분석',
  info: [
    {
      iconName: ICON.exposedaccount,
      title: '인기게시물/노출 계정 분석이란?',
      content:
        '높은 반응도를 받고 있는 계정,/게시물 리스트를 제공하여/빠르게 변화하는 트렌드 분석',
    },
    {
      iconName: ICON.targetuser,
      title: '추천 사용자',
      content:
        '연관 관심사 내에서 인기있는/계정과 게시물의 특징을 파악,/혹은 벤치마킹 하고 싶은 분',
    },
    {
      iconName: ICON.analysis,
      title: '분석항목',
      content:
        '- 기간별 인기게시물 계정 순위/- 해시태그 TOP 좋아요 게시물/- 해시태그 TOP 댓글 게시물',
    },
  ],
};

export const ACCOUNT_DESCRIPTION = {
  iconName: 'Account',
  title: '계정 분석',
  info: [
    {
      iconName: ICON.account,
      title: '계정 분석이란?',
      content:
        '검색한 계정의 활동 이력을/바탕으로 인기게시물 노출과/인스타그램 마케팅 적합도/측정을 돕는 기능',
    },
    {
      iconName: ICON.targetuser,
      title: '추천 사용자',
      content:
        '계정 성장치를 파악하고/싶거나, 해당 계정으로/마케팅 시 어느 정도/성과가 있을 지 알고 싶으신 분',
    },
    {
      iconName: ICON.analysis,
      title: '분석항목',
      content:
        '- 기간 내 계정 활동량 추이/- 기간 내 획득 반응량 추이/- 인기게시물 노출 해시태그/- 인기게시물 노출 적합도',
    },
  ],
};

export const POSTINCREASE_DESCRIPTION = {
  iconName: 'PostIncrease',
  title: '게시물 증가 추이',
  info: [
    {
      iconName: ICON.postincrease,
      title: '게시물 증가 추이란?',
      content:
        '검색한 해시태그의/포스트 증가 추이를 분석하여/기간별 마케팅 경쟁률울/확인할 수 있는 기능',
    },
    {
      iconName: ICON.targetuser,
      title: '추천 사용자',
      content:
        '해당 업종 혹은 분야의 성수기,/비성수기를 파악하여/전략적인 마케팅을/진행하고 싶은 분',
    },
    {
      iconName: ICON.analysis,
      title: '분석항목',
      content:
        '- 월간 평균 게시물 증가 수/- 기간별 게시물 증가 순위/- 포스트 증가 추이',
    },
  ],
};
