import { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

interface Props {
  tooltip: string | undefined;
  dates: string[] | undefined;
  values: number[] | undefined;
}

const AreaChart = ({ tooltip, dates, values }: Props) => {
  const tickAmount = 5;

  const [series, setSeries] = useState<any>([
    {
      name: 'chart',
      data: values,
    },
  ]);
  const [options, setOptions] = useState({
    chart: {
      width: '100%',
      zoom: { enabled: false },
      toolbar: { show: false },
      animations: {
        enabled: true,
        speed: 1000,
      },
    },
    colors: ['#4B87FF'],
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.6,
        inverseColors: false,
      },
    },
    stroke: {
      width: 3,
    },
    xaxis: {
      categories: dates,
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount,
      labels: {
        formatter(val: number) {
          return val.toFixed(0);
        },
      },
    },
    tooltip: {
      enabled: true,
      custom: (param: any) => {
        return `
          <div style="background-color:#fff; padding: 10px;">
            ${param.ctx.w.globals.categoryLabels[param.dataPointIndex]}<br/>
            ${tooltip}: <strong>${
          param.series[0][param.dataPointIndex]
        }</strong>
          </div>`;
      },
    },
  });
  useEffect(() => {
    setTimeout(() => {
      setSeries([
        {
          name: 'chart',
          data: values,
        },
      ]);
      setOptions({
        chart: {
          width: '100%',
          zoom: { enabled: false },
          toolbar: { show: false },
          animations: {
            enabled: true,
            speed: 1000,
          },
        },
        colors: ['#4B87FF'],
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'vertical',
            shadeIntensity: 0.6,
            inverseColors: false,
          },
        },
        stroke: {
          width: 3,
        },
        xaxis: {
          categories: dates,
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tickAmount,
          labels: {
            formatter(val: number) {
              return val.toFixed(0);
            },
          },
        },
        tooltip: {
          enabled: true,
          custom: (param: any) => {
            return `
              <div style="background-color:#fff; padding: 10px;">
                ${param.ctx.w.globals.categoryLabels[param.dataPointIndex]}<br/>
                ${tooltip}: <strong>${
              param.series[0][param.dataPointIndex]
            }</strong>
              </div>`;
          },
        },
      });
    }, 100);
  }, [dates, values]);
  return (
    <div id="chart" className="chartLibrary">
      <Chart type="area" height={140} options={options} series={series} />
    </div>
  );
};

export default AreaChart;
