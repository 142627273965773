import styled, { css } from 'styled-components';

const BoxDefault = css<BoxStyle>`
  position: relative;
  z-index: 1;
  width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  /* height: ${({ height }) => height}px; */
  min-height: ${({ height }) => height}px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.1);
`;

export const SmallPublicBox = styled.div`
  ${BoxDefault}
  display: flex;
  padding: 11px 20px;
`;
export const PublicBox = styled.div`
  ${BoxDefault}
  padding: 20px;
`;
