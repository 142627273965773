import { useState, useEffect } from 'react';
import { useSpringCarousel } from 'react-spring-carousel-js';
import ArrowLeftPath from '@assets/images/Carousel_arrowleft@2x.png';
import ArrowRightPath from '@assets/images/Carousel_arrowright@2x.png';
import * as S from './Carousel.style';

const CarouselItem = ({
  message,
  image,
}: {
  message: string;
  image: string;
}) => (
  <S.ItemContainer>
    <S.Message src={message} alt="" />
    <S.Item src={image} alt="" />
  </S.ItemContainer>
);

interface Props {
  CarouselMessages: string[];
  CarouselImages: string[];
}

export const Carousel = ({ CarouselMessages, CarouselImages }: Props) => {
  const [count, setCount] = useState(0);

  const { carouselFragment, slideToPrevItem, slideToNextItem } =
    useSpringCarousel({
      withLoop: true,
      items: CarouselImages.map((image, index) => {
        return {
          id: `CarouselItem-${index}`,
          renderItem: (
            <CarouselItem message={CarouselMessages[index]} image={image} />
          ),
        };
      }),
    });
  useEffect(() => {
    const interval = setInterval(() => {
      slideToNextItem();
    }, 7000);

    return () => {
      window.clearInterval(interval);
    };
  }, [count]);
  const handleLeft = () => {
    slideToPrevItem();
    setCount(count + 1);
  };
  const hnandleRight = () => {
    slideToNextItem();
    setCount(count + 1);
  };
  return (
    <S.Container>
      <S.ArrowLeft src={ArrowLeftPath} onClick={handleLeft} />
      {carouselFragment}
      <S.ArrowRight src={ArrowRightPath} onClick={hnandleRight} />
    </S.Container>
  );
};
